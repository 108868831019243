import { Injectable } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';

export enum MsgType { info, error }

export class LogMsg {
  constructor(private type: MsgType, private message: any, private optionalParams?: any[]) {
  }

  getType(): MsgType {
    return this.type;
  }

  getMessage(): any {
    return this.message;
  }

  getOptionalParams(): any[] {
    return this.optionalParams;
  }

  getMessageAsString(): string {
    if (typeof this.message === 'string') {
      return this.message;
    } else {
      return JSON.stringify(this.message);
    }
  }

  getOptionalParamsAsString(): string {
    if (this.optionalParams) {
      return this.optionalParams.map((optParam) => {
        if (typeof optParam === 'string') {
          return optParam;
        } else {
          return JSON.stringify(optParam, null, 4);
        }
      }).join('\n');
    } else {
      return '';
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  private logSubject: Subject<LogMsg> = new Subject();

  getLogMessages(): Observable<LogMsg> {
    return this.logSubject.asObservable();
  }

  info(message?: any, ...optionalParams: any[]) {
    this.logSubject.next(new LogMsg(MsgType.info, message, optionalParams));
  }

  warn(message?: any, ...optionalParams: any[]) {
    this.logSubject.next(new LogMsg(MsgType.error, message, optionalParams));
  }

  error(message?: any, cb?: () => any, ...optionalParams: any[]) {
    this.logSubject.next(new LogMsg(MsgType.error, message, optionalParams));
    // if (cb) {
    //   timer(5000).subscribe(i => cb());
    // }
  }
}
