import { NgModule } from '@angular/core';

import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';

import { GraphQLUri, XApiKey } from '../../../const.exports';
import { resolvers } from './resolvers';

export function createApollo(httpLink: HttpLink) {
  const cache = new InMemoryCache({
    // addTypename: false
    // __typename property needs to be deleted on every model mutation - not practicable - but needed for Apollo
    // fragments and to hide Apollo warning messages
    dataIdFromObject: object => {
      switch (object.__typename) {
        case 'Option': return null;   // Use default path caching for 'Option' objects
        default: return object.id;
      }
    }
  });

  const auth = setContext((_, { headers }) => {
    return {
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': XApiKey
      }
    };
  });

  const http = httpLink.create({ uri: GraphQLUri });

  return {
    cache,
    link: auth.concat(http),
    resolvers
  };
}

@NgModule({
  exports: [ApolloModule, HttpLinkModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLClientModule { }
