import { Component } from '@angular/core';
import { SuiModal } from 'ng2-semantic-ui';
import { saveAs } from 'file-saver';

import {
  CSVExpSep,
  GetQuestionnaireTypeLabel,
  PersonRoles,
  QuestionnaireId,
  QuestionTypes
} from '../../../../const.exports';
import { DataService } from '../../../shared/data.service';
import {
  CreateSurveyInput,
  Option,
  PersonInput,
  PersonRole,
  QuestionInput,
  QuestionnaireType,
  QuestionType,
  Survey,
  UpdateSurveyInput
} from '../../../shared/graphql/graphql-client.service';

export interface ISurveyEditContext {
  survey: Survey;
}

@Component({
  selector: 'bkp-survey-edit',
  templateUrl: './survey-edit.component.html',
  styles: []
})
export class SurveyEditComponent {

  QuestionnaireType = QuestionnaireType;
  getQuestionnaireTypeLabel = GetQuestionnaireTypeLabel;

  editHeader: string;
  surveyForm: CreateSurveyInput | UpdateSurveyInput;
  updating: boolean;

  deadlineDate = new Date();
  minDate = new Date();
  removeSurveyConfirmEnabled = false;

  intervieweeUploadEnabled = false;
  customQuestionUploadEnabled = false;

  invalid = {
    surveyType: false
  };

  constructor(public modal: SuiModal<ISurveyEditContext, CreateSurveyInput | UpdateSurveyInput | string, void>,
              private dataService: DataService) {

    const survey = this.modal.context.survey;
    if (survey.type) {
      this.editHeader = this.getQuestionnaireTypeLabel(survey.type)
          + ` vom ${this.dataService.getDateFormatted(survey.created)} bearbeiten`;
      this.surveyForm = { ...survey } as UpdateSurveyInput;
      this.deadlineDate = this.dataService.getDate(this.surveyForm.deadline);
      this.updating = true;
    } else {
      this.editHeader = 'Neuen Check erstellen';
      this.surveyForm = {
        ...survey,
        customQuestions: [] as QuestionInput[],
        interviewees: [] as PersonInput[]
      } as CreateSurveyInput;
      this.updating = false;
    }

    this.addInterviewee();
  }

  setQuestionnaireType(questionnaireType: QuestionnaireType) {
    this.surveyForm.type = questionnaireType;
  }

  addInterviewee() {
    const person = {} as PersonInput;
    this.surveyForm.interviewees = this.surveyForm.interviewees
      ? [...this.surveyForm.interviewees, person] : [person];
  }

  removeInterviewee(eMail: string) {
    this.surveyForm.interviewees = this.surveyForm.interviewees.filter(
      (interviewee: PersonInput) => interviewee.eMail !== eMail);
  }

  toggleIntervieweeUpload() {
    this.intervieweeUploadEnabled = !this.intervieweeUploadEnabled;
  }

  uploadIntervieweeCSV(event) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const csvContent = e.target.result.trim();
      const sep = ((csvContent.split(';').length - 1) > (csvContent.split('\t').length - 1))
        ? ';' : '\t';
      const csvLines = csvContent.replace('\r', '').split('\n');

      const personInputs: PersonInput[] = [];
      let invalidLines = '';
      for (let i = 0; i < csvLines.length; i++) {
        const items = csvLines[i].split(sep);
        if (items.length > 0 && items.length !== 3) {
          invalidLines += `Zeile ${i + 1} (3 Einträge erforderlich): ${csvLines[i]}\n`;
        } else {
          personInputs.push({
            firstName: items[0].trim(),
            lastName: items[1].trim(),
            eMail: items[2].trim(),
            role: null
          });
          // const thisPersonRole = PersonRoles.find(personRole => personRole.label === items[3].trim());
          // if (thisPersonRole) {
          //   personInputs.push({
          //     firstName: items[0].trim(),
          //     lastName: items[1].trim(),
          //     eMail: items[2].trim(),
          //     role: thisPersonRole ? thisPersonRole.value : null
          //   });
          // } else {
          //   invalidLines += `Zeile ${i + 1} (Ungültige Rolle): ${csvLines[i]}\n`;
          // }
        }
      }

      if (invalidLines === '') {
        personInputs.forEach(personInput => this.surveyForm.interviewees.push(personInput));
      } else {
        alert('Ungültige Zeilen:\n' + invalidLines);
      }
    };
    reader.readAsText(event.target.files[0]);
    this.intervieweeUploadEnabled = false;
  }

  intervieweesExport(interviewees: PersonInput[]) {
    const csv = [];
    interviewees.forEach((interviewee: PersonInput) => {
      // const roleLabel = PersonRoles.find(role => role.value === interviewee.role).label;
      csv.push([interviewee.firstName, interviewee.lastName, interviewee.eMail].join(CSVExpSep));
    });
    const blob = new Blob([csv.join('\n')], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `Umfrageteilnehmer${this.surveyForm.id ? '-' + this.surveyForm.id : ''}.csv`);
  }

  addCustomQuestion() {
    const customQuestion = {} as QuestionInput;
    customQuestion.id = this.dataService.generateUUID();
    customQuestion.type = QuestionType.TextInput;
    customQuestion.question = '';
    this.surveyForm.customQuestions = this.surveyForm.customQuestions
      ? [...this.surveyForm.customQuestions, customQuestion] : [customQuestion];
  }

  removeCustomQuestion(id: string) {
    this.surveyForm.customQuestions = this.surveyForm.customQuestions.filter(
      (customQuestion: QuestionInput) => customQuestion.id !== id);
  }

  toggleCustomQuestionUpload() {
    this.customQuestionUploadEnabled = !this.customQuestionUploadEnabled;
  }

  uploadCustomQuestionCSV(event) {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const csvContent = e.target.result.trim();
      const sep = ((csvContent.split(';').length - 1) > (csvContent.split('\t').length - 1))
        ? ';' : '\t';
      const csvLines = csvContent.replace('\r', '').split('\n');

      const customQuestions: QuestionInput[] = [];
      let invalidLines = '';
      for (let i = 0; i < csvLines.length; i++) {
        const items = csvLines[i].split(sep);
        if (items.length > 0 && items.length < 2) {
          invalidLines += `Zeile ${i + 1} (Mind. 2 Einträge erforderlich): ${csvLines[i]}\n`;
        } else {
          const thisQuestionType = QuestionTypes.find(questionType => questionType.label === items[1].trim());
          if (thisQuestionType) {
            const customQuestion: QuestionInput = {
              id: this.dataService.generateUUID(),
              type: thisQuestionType.value,
              question: items[0].trim()
            };

            if (thisQuestionType.value !== QuestionType.TextInput) {
              items.forEach((item, idx) => {
                if (idx > 1 && item.trim()) {
                  const option: Option = {
                    id: idx - 1,
                    label: item.trim()
                  };
                  customQuestion.optionSet = customQuestion.optionSet ? [...customQuestion.optionSet, option] : [option];
                }
              });
            }

            customQuestions.push(customQuestion);
          } else {
            invalidLines += `Zeile ${i + 1} (Ungültiger Fragetyp): ${csvLines[i]}\n`;
          }
        }
      }

      if (invalidLines === '') {
        customQuestions.forEach(customQuestion => this.surveyForm.customQuestions.push(customQuestion));
      } else {
        alert('Ungültige Zeilen:\n' + invalidLines);
      }
    };
    reader.readAsText(event.target.files[0]);
    this.customQuestionUploadEnabled = false;
  }

  customQuestionsExport(customQuestions: QuestionInput[]) {
    const csv = [];
    customQuestions.forEach((customQuestion: QuestionInput) => {
      const typeLabel = QuestionTypes.find(type => type.value === customQuestion.type).label;

      csv.push([customQuestion.question, typeLabel,
        (customQuestion.optionSet ? customQuestion.optionSet.map(option => option.label).join(CSVExpSep) : '')].join(CSVExpSep));
    });
    const blob = new Blob([csv.join('\n')], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `Unternehmensfragen${this.surveyForm.id ? '-' + this.surveyForm.id : ''}.csv`);
  }

  getQuestionnaireId(): string {
    switch (this.surveyForm.type) {
      case QuestionnaireType.BetriebsCheck: return QuestionnaireId.BetriebsCheck;
      case QuestionnaireType.FremdCheckDv: return QuestionnaireId.FremdCheckDV;
      case QuestionnaireType.FremdCheckPa: return QuestionnaireId.FremdCheckPA;
      case QuestionnaireType.FremdCheckBi: return QuestionnaireId.FremdCheckBI;
      default: return QuestionnaireId.SelbstCheck;
    }
  }

  getRoleForQuestionnaireType(): PersonRole {
    switch (this.surveyForm.type) {
      case QuestionnaireType.BetriebsCheck: return PersonRole.Employer;
      case QuestionnaireType.FremdCheckDv: return PersonRole.ManagerDv;
      case QuestionnaireType.FremdCheckPa: return PersonRole.ManagerPa;
      case QuestionnaireType.FremdCheckBi: return PersonRole.ManagerBi;
      default: return PersonRole.Employee;
    }
  }

  recheckDelete(id: string) {
    if (window.confirm('Wollen Sie diese Umfrage wirklich löschen?')) {
      this.modal.approve(id);
    } else {
      this.modal.deny(undefined);
    }
  }

  removeEmptyLines() {
    this.surveyForm.interviewees = this.surveyForm.interviewees.filter(
      (pi: PersonInput) => pi && pi.eMail && pi.firstName && pi.lastName);
    this.surveyForm.customQuestions = this.surveyForm.customQuestions.filter(
      (qi: QuestionInput) => qi && qi.question);
  }

  save() {
    if (!this.surveyForm.type) {
      this.invalid.surveyType = true;
      return;
    } else {
      this.invalid.surveyType = false;
    }

    this.surveyForm.deadline = this.dataService.getTimestamp(this.deadlineDate);
    this.surveyForm.questionnaireId = this.getQuestionnaireId();
    this.removeEmptyLines();
    this.surveyForm.interviewees.forEach(
      (interviewee: PersonInput) => interviewee.role = this.getRoleForQuestionnaireType());
    this.surveyForm.customQuestions = this.surveyForm.customQuestions.filter(
      (customQuestion: QuestionInput) => customQuestion.question);

    this.modal.approve(this.surveyForm);
  }
}
