import gql from 'graphql-tag';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};



export type Address = {
  __typename?: 'Address';
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type Answer = {
  __typename?: 'Answer';
  id: Scalars['ID'];
  surveyId: Scalars['ID'];
  questionId: Scalars['ID'];
  userToken: Scalars['ID'];
  answer?: Maybe<AnswerContent>;
  created?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['Int']>;
};

export type AnswerChoice = {
  __typename?: 'AnswerChoice';
  idx?: Maybe<Scalars['Int']>;
  choice?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

export type AnswerConnection = {
  __typename?: 'AnswerConnection';
  items?: Maybe<Array<Maybe<Answer>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type AnswerContent = {
  __typename?: 'AnswerContent';
  type: QuestionType;
  subType?: Maybe<QuestionSubType>;
  text?: Maybe<Scalars['String']>;
  optionsSelected?: Maybe<Array<Maybe<Option>>>;
  count?: Maybe<Scalars['Int']>;
};

export type AnswerContentInput = {
  type: QuestionType;
  subType?: Maybe<QuestionSubType>;
  text?: Maybe<Scalars['String']>;
  optionsSelected?: Maybe<Array<Maybe<OptionInput>>>;
  count?: Maybe<Scalars['Int']>;
};

export type Contact = {
  __typename?: 'Contact';
  contactPerson?: Maybe<Scalars['String']>;
  eMail?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  webUrls?: Maybe<Array<Scalars['String']>>;
};

export type ContactInput = {
  contactPerson?: Maybe<Scalars['String']>;
  eMail?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  webUrls?: Maybe<Array<Scalars['String']>>;
};

export type Context = {
  __typename?: 'Context';
  type?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  introductoryVideo?: Maybe<Scalars['String']>;
  info?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<Question>>;
  contexts?: Maybe<Array<Maybe<Context>>>;
};

export type ContextInput = {
  type?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  introductoryVideo?: Maybe<Scalars['String']>;
  info?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<QuestionInput>>;
  contexts?: Maybe<Array<Maybe<ContextInput>>>;
};

export type CreateAnswerInput = {
  surveyId: Scalars['ID'];
  questionId: Scalars['ID'];
  userToken: Scalars['ID'];
  answer?: Maybe<AnswerContentInput>;
  created?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['Int']>;
};

export type CreateOrganizationalUnitInput = {
  parentId?: Maybe<Scalars['ID']>;
  type?: Maybe<OuType>;
  name: Scalars['String'];
  contact?: Maybe<ContactInput>;
  address?: Maybe<AddressInput>;
};

export type CreateQuestionnaireInput = {
  contexts?: Maybe<Array<ContextInput>>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type CreateSurveyInput = {
  id?: Maybe<Scalars['ID']>;
  label: Scalars['String'];
  organizationalUnitId: Scalars['ID'];
  type: QuestionnaireType;
  questionnaireId: Scalars['ID'];
  customQuestions?: Maybe<Array<QuestionInput>>;
  interviewees?: Maybe<Array<PersonInput>>;
  questionnairesCompleted?: Maybe<Scalars['Int']>;
  deadline: Scalars['Int'];
  status: SurveyStatus;
  published?: Maybe<Scalars['Int']>;
  finalized?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type CreateUserInput = {
  id: Scalars['ID'];
  type: UserType;
  name: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  ouTreeAccessAllowed?: Maybe<Array<Scalars['ID']>>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type CreateUserQuestionnaireInput = {
  type: QuestionnaireType;
  surveyId: Scalars['ID'];
  userToken: Scalars['ID'];
  questionnaireId: Scalars['ID'];
  questionnaire?: Maybe<CreateQuestionnaireInput>;
  customQuestions?: Maybe<Array<QuestionInput>>;
  status: UserQuestionnaireStatus;
  published?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type DeleteAnswerInput = {
  id: Scalars['ID'];
};

export type DeleteOrganizationalUnitInput = {
  id: Scalars['ID'];
};

export type DeleteQuestionnaireInput = {
  id: Scalars['ID'];
};

export type DeleteSurveyInput = {
  id: Scalars['ID'];
};

export type DeleteUserInput = {
  id: Scalars['ID'];
};

export type DeleteUserQuestionnaireInput = {
  id: Scalars['ID'];
};

export type EvaluationAnswer = {
  __typename?: 'EvaluationAnswer';
  ivId: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  choices?: Maybe<Array<Maybe<AnswerChoice>>>;
};

export type EvaluationContext = {
  __typename?: 'EvaluationContext';
  header?: Maybe<Scalars['String']>;
  questionAndAnswers?: Maybe<Array<QuestionAndAnswers>>;
  contexts?: Maybe<Array<EvaluationContext>>;
};

export enum EvaluationQuestionType {
  Text = 'Text',
  SingleChoice = 'SingleChoice',
  MultipleChoice = 'MultipleChoice',
  SingleCount = 'SingleCount',
  MultipleCount = 'MultipleCount'
}

export type EvaluationResult = {
  __typename?: 'EvaluationResult';
  interviewees?: Maybe<Array<Interviewee>>;
  contexts?: Maybe<Array<EvaluationContext>>;
};

export type Interviewee = {
  __typename?: 'Interviewee';
  id: Scalars['ID'];
  role: PersonRole;
  ageGroup?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  qualification?: Maybe<Scalars['String']>;
  migration?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createOrganizationalUnit?: Maybe<OrganizationalUnit>;
  updateOrganizationalUnit?: Maybe<OrganizationalUnit>;
  deleteOrganizationalUnit?: Maybe<OrganizationalUnit>;
  createSurvey?: Maybe<Survey>;
  updateSurvey?: Maybe<Survey>;
  deleteSurvey?: Maybe<Survey>;
  createUserQuestionnaire?: Maybe<UserQuestionnaire>;
  updateUserQuestionnaire?: Maybe<UserQuestionnaire>;
  deleteUserQuestionnaire?: Maybe<UserQuestionnaire>;
  createQuestionnaire?: Maybe<Questionnaire>;
  updateQuestionnaire?: Maybe<Questionnaire>;
  deleteQuestionnaire?: Maybe<Questionnaire>;
  createAnswer?: Maybe<Answer>;
  updateAnswer?: Maybe<Answer>;
  deleteAnswer?: Maybe<Answer>;
  createUser?: Maybe<User>;
  updateUser?: Maybe<User>;
  deleteUser?: Maybe<User>;
};


export type MutationCreateOrganizationalUnitArgs = {
  input: CreateOrganizationalUnitInput;
};


export type MutationUpdateOrganizationalUnitArgs = {
  input: UpdateOrganizationalUnitInput;
};


export type MutationDeleteOrganizationalUnitArgs = {
  input: DeleteOrganizationalUnitInput;
};


export type MutationCreateSurveyArgs = {
  input: CreateSurveyInput;
};


export type MutationUpdateSurveyArgs = {
  input: UpdateSurveyInput;
};


export type MutationDeleteSurveyArgs = {
  input: DeleteSurveyInput;
};


export type MutationCreateUserQuestionnaireArgs = {
  input: CreateUserQuestionnaireInput;
};


export type MutationUpdateUserQuestionnaireArgs = {
  input: UpdateUserQuestionnaireInput;
};


export type MutationDeleteUserQuestionnaireArgs = {
  input: DeleteUserQuestionnaireInput;
};


export type MutationCreateQuestionnaireArgs = {
  input: CreateQuestionnaireInput;
};


export type MutationUpdateQuestionnaireArgs = {
  input: UpdateQuestionnaireInput;
};


export type MutationDeleteQuestionnaireArgs = {
  input: DeleteQuestionnaireInput;
};


export type MutationCreateAnswerArgs = {
  input: CreateAnswerInput;
};


export type MutationUpdateAnswerArgs = {
  input: UpdateAnswerInput;
};


export type MutationDeleteAnswerArgs = {
  input: DeleteAnswerInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};

export type Option = {
  __typename?: 'Option';
  id: Scalars['Int'];
  label: Scalars['String'];
  count?: Maybe<Scalars['Int']>;
};

export type OptionInput = {
  id: Scalars['Int'];
  label: Scalars['String'];
  count?: Maybe<Scalars['Int']>;
};

export type OrganizationalUnit = {
  __typename?: 'OrganizationalUnit';
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  type?: Maybe<OuType>;
  name: Scalars['String'];
  contact?: Maybe<Contact>;
  address?: Maybe<Address>;
  organizationalUnits?: Maybe<OrganizationalUnitConnection>;
  surveys?: Maybe<SurveyConnection>;
};


export type OrganizationalUnitOrganizationalUnitsArgs = {
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type OrganizationalUnitSurveysArgs = {
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type OrganizationalUnitConnection = {
  __typename?: 'OrganizationalUnitConnection';
  items?: Maybe<Array<Maybe<OrganizationalUnit>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum OuType {
  AuL = 'AuL',
  Company = 'Company',
  Department = 'Department'
}

export type Person = {
  __typename?: 'Person';
  role: PersonRole;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  eMail: Scalars['String'];
};

export type PersonInput = {
  role: PersonRole;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  eMail: Scalars['String'];
};

export enum PersonRole {
  Employee = 'Employee',
  ManagerDv = 'ManagerDV',
  ManagerPa = 'ManagerPA',
  ManagerBi = 'ManagerBI',
  Employer = 'Employer'
}

export type Query = {
  __typename?: 'Query';
  getOrganizationalUnit?: Maybe<OrganizationalUnit>;
  listOrganizationalUnits?: Maybe<OrganizationalUnitConnection>;
  queryOrganizationalUnitsByParentId?: Maybe<OrganizationalUnitConnection>;
  queryOrganizationalUnitsByName?: Maybe<OrganizationalUnitConnection>;
  getSurvey?: Maybe<Survey>;
  listSurveys?: Maybe<SurveyConnection>;
  querySurveysByOrganizationalUnitId?: Maybe<SurveyConnection>;
  getUserQuestionnaire?: Maybe<UserQuestionnaire>;
  listUserQuestionnaires?: Maybe<UserQuestionnaireConnection>;
  getQuestionnaire?: Maybe<Questionnaire>;
  listQuestionnaires?: Maybe<QuestionnaireConnection>;
  getAnswer?: Maybe<Answer>;
  listAnswers?: Maybe<AnswerConnection>;
  queryAnswersBySurveyId?: Maybe<AnswerConnection>;
  evaluateSurveysByOUAndDate?: Maybe<EvaluationResult>;
  getUser?: Maybe<User>;
  listUsers?: Maybe<UserConnection>;
};


export type QueryGetOrganizationalUnitArgs = {
  id: Scalars['ID'];
};


export type QueryListOrganizationalUnitsArgs = {
  filter?: Maybe<TableOrganizationalUnitFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryQueryOrganizationalUnitsByParentIdArgs = {
  parentId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryQueryOrganizationalUnitsByNameArgs = {
  name: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryGetSurveyArgs = {
  id: Scalars['ID'];
};


export type QueryListSurveysArgs = {
  filter?: Maybe<TableSurveyFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryQuerySurveysByOrganizationalUnitIdArgs = {
  organizationalUnitId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryGetUserQuestionnaireArgs = {
  id: Scalars['ID'];
};


export type QueryListUserQuestionnairesArgs = {
  filter?: Maybe<TableUserQuestionnaireFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryGetQuestionnaireArgs = {
  id: Scalars['ID'];
};


export type QueryListQuestionnairesArgs = {
  filter?: Maybe<TableQuestionnaireFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryGetAnswerArgs = {
  id: Scalars['ID'];
};


export type QueryListAnswersArgs = {
  filter?: Maybe<TableAnswerFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type QueryQueryAnswersBySurveyIdArgs = {
  surveyId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
};


export type QueryEvaluateSurveysByOuAndDateArgs = {
  ouId: Scalars['ID'];
  date: Scalars['Int'];
};


export type QueryGetUserArgs = {
  id: Scalars['ID'];
};


export type QueryListUsersArgs = {
  filter?: Maybe<TableUserFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Question = {
  __typename?: 'Question';
  id: Scalars['ID'];
  replacesQuestion?: Maybe<Scalars['ID']>;
  type: QuestionType;
  subType?: Maybe<QuestionSubType>;
  question: Scalars['String'];
  section?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  optionSet?: Maybe<Array<Maybe<Option>>>;
  optionSetId?: Maybe<Scalars['ID']>;
};

export type QuestionAndAnswers = {
  __typename?: 'QuestionAndAnswers';
  id: Scalars['ID'];
  type: EvaluationQuestionType;
  subType?: Maybe<QuestionSubType>;
  question: Scalars['String'];
  section?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  choices?: Maybe<Array<Scalars['String']>>;
  answers?: Maybe<Array<Maybe<EvaluationAnswer>>>;
};

export type QuestionConnection = {
  __typename?: 'QuestionConnection';
  items?: Maybe<Array<Maybe<Question>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type QuestionInput = {
  id: Scalars['ID'];
  replacesQuestion?: Maybe<Scalars['ID']>;
  type: QuestionType;
  subType?: Maybe<QuestionSubType>;
  question: Scalars['String'];
  section?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  instruction?: Maybe<Scalars['String']>;
  optionSet?: Maybe<Array<Maybe<OptionInput>>>;
  optionSetId?: Maybe<Scalars['ID']>;
};

export type Questionnaire = {
  __typename?: 'Questionnaire';
  id: Scalars['ID'];
  contexts?: Maybe<Array<Context>>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type QuestionnaireConnection = {
  __typename?: 'QuestionnaireConnection';
  items?: Maybe<Array<Maybe<Questionnaire>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum QuestionnaireType {
  SelbstCheck = 'SelbstCheck',
  FremdCheckDv = 'FremdCheckDV',
  FremdCheckPa = 'FremdCheckPA',
  FremdCheckBi = 'FremdCheckBI',
  BetriebsCheck = 'BetriebsCheck'
}

export enum QuestionSubType {
  Scale = 'Scale',
  Table = 'Table'
}

export enum QuestionType {
  TextInput = 'textInput',
  SingleCountInput = 'singleCountInput',
  MultipleCountInput = 'multipleCountInput',
  SingleChoice = 'singleChoice',
  MultipleChoice = 'multipleChoice'
}

export type Survey = {
  __typename?: 'Survey';
  id: Scalars['ID'];
  label: Scalars['String'];
  organizationalUnitId: Scalars['ID'];
  type: QuestionnaireType;
  questionnaireId: Scalars['ID'];
  questionnaire?: Maybe<Questionnaire>;
  surveyResult?: Maybe<Questionnaire>;
  customQuestions?: Maybe<Array<Question>>;
  interviewees?: Maybe<Array<Person>>;
  questionnairesCompleted?: Maybe<Scalars['Int']>;
  deadline: Scalars['Int'];
  status: SurveyStatus;
  published?: Maybe<Scalars['Int']>;
  finalized?: Maybe<Scalars['Int']>;
  answers?: Maybe<AnswerConnection>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};


export type SurveyAnswersArgs = {
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};

export type SurveyConnection = {
  __typename?: 'SurveyConnection';
  items?: Maybe<Array<Maybe<Survey>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum SurveyStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export type TableAnswerFilterInput = {
  surveyId?: Maybe<TableIdFilterInput>;
  questionId?: Maybe<TableIdFilterInput>;
  userToken?: Maybe<TableIdFilterInput>;
  answer?: Maybe<AnswerContentInput>;
  created?: Maybe<TableIntFilterInput>;
  updated?: Maybe<TableIntFilterInput>;
};

export type TableBooleanFilterInput = {
  ne?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['Boolean']>;
};

export type TableFloatFilterInput = {
  ne?: Maybe<Scalars['Float']>;
  eq?: Maybe<Scalars['Float']>;
  le?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  ge?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  contains?: Maybe<Scalars['Float']>;
  notContains?: Maybe<Scalars['Float']>;
  between?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type TableIdFilterInput = {
  ne?: Maybe<Scalars['ID']>;
  eq?: Maybe<Scalars['ID']>;
  le?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  ge?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  contains?: Maybe<Scalars['ID']>;
  notContains?: Maybe<Scalars['ID']>;
  between?: Maybe<Array<Maybe<Scalars['ID']>>>;
  beginsWith?: Maybe<Scalars['ID']>;
};

export type TableIntFilterInput = {
  ne?: Maybe<Scalars['Int']>;
  eq?: Maybe<Scalars['Int']>;
  le?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  ge?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  contains?: Maybe<Scalars['Int']>;
  notContains?: Maybe<Scalars['Int']>;
  between?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type TableOrganizationalUnitFilterInput = {
  id?: Maybe<TableIdFilterInput>;
  parentId?: Maybe<TableIdFilterInput>;
  type?: Maybe<TableOuTypeFilterInput>;
  name?: Maybe<TableStringFilterInput>;
};

export type TableOuTypeFilterInput = {
  eq?: Maybe<OuType>;
  ne?: Maybe<OuType>;
};

export type TableQuestionnaireFilterInput = {
  created?: Maybe<TableIntFilterInput>;
  createdBy?: Maybe<TableStringFilterInput>;
  updated?: Maybe<TableIntFilterInput>;
  updatedBy?: Maybe<TableStringFilterInput>;
};

export type TableQuestionnaireTypeFilterInput = {
  eq?: Maybe<QuestionnaireType>;
  ne?: Maybe<QuestionnaireType>;
};

export type TableStringFilterInput = {
  ne?: Maybe<Scalars['String']>;
  eq?: Maybe<Scalars['String']>;
  le?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  ge?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  notContains?: Maybe<Scalars['String']>;
  between?: Maybe<Array<Maybe<Scalars['String']>>>;
  beginsWith?: Maybe<Scalars['String']>;
};

export type TableSurveyFilterInput = {
  organizationalUnitId?: Maybe<TableIdFilterInput>;
  type?: Maybe<TableQuestionnaireTypeFilterInput>;
  questionnaireId?: Maybe<TableIdFilterInput>;
  questionnairesCompleted?: Maybe<TableIntFilterInput>;
  deadline?: Maybe<TableIntFilterInput>;
  status?: Maybe<TableSurveyStatusFilterInput>;
  published?: Maybe<TableIntFilterInput>;
  finalized?: Maybe<TableIntFilterInput>;
  created?: Maybe<TableIntFilterInput>;
  createdBy?: Maybe<TableStringFilterInput>;
  updated?: Maybe<TableIntFilterInput>;
  updatedBy?: Maybe<TableStringFilterInput>;
};

export type TableSurveyStatusFilterInput = {
  eq?: Maybe<SurveyStatus>;
  ne?: Maybe<SurveyStatus>;
};

export type TableUserFilterInput = {
  id?: Maybe<TableIdFilterInput>;
  type?: Maybe<UserType>;
  name?: Maybe<TableStringFilterInput>;
  email?: Maybe<TableStringFilterInput>;
  phone?: Maybe<TableStringFilterInput>;
  created?: Maybe<TableIntFilterInput>;
  createdBy?: Maybe<TableStringFilterInput>;
  updated?: Maybe<TableIntFilterInput>;
  updatedBy?: Maybe<TableStringFilterInput>;
};

export type TableUserQuestionnaireFilterInput = {
  type?: Maybe<TableUserQuestionnaireTypeFilterInput>;
  surveyId?: Maybe<TableStringFilterInput>;
  userToken?: Maybe<TableIdFilterInput>;
  questionnaireId?: Maybe<TableIdFilterInput>;
  status?: Maybe<TableUserQuestionnaireStatusFilterInput>;
  published?: Maybe<TableIntFilterInput>;
  created?: Maybe<TableIntFilterInput>;
  createdBy?: Maybe<TableStringFilterInput>;
  updated?: Maybe<TableIntFilterInput>;
  updatedBy?: Maybe<TableStringFilterInput>;
};

export type TableUserQuestionnaireStatusFilterInput = {
  eq?: Maybe<UserQuestionnaireStatus>;
  ne?: Maybe<UserQuestionnaireStatus>;
};

export type TableUserQuestionnaireTypeFilterInput = {
  eq?: Maybe<QuestionnaireType>;
  ne?: Maybe<QuestionnaireType>;
};

export type UpdateAnswerInput = {
  id: Scalars['ID'];
  surveyId?: Maybe<Scalars['ID']>;
  questionId?: Maybe<Scalars['ID']>;
  userToken?: Maybe<Scalars['ID']>;
  answer?: Maybe<AnswerContentInput>;
  created?: Maybe<Scalars['Int']>;
  updated?: Maybe<Scalars['Int']>;
};

export type UpdateOrganizationalUnitInput = {
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  type?: Maybe<OuType>;
  name?: Maybe<Scalars['String']>;
  contact?: Maybe<ContactInput>;
  address?: Maybe<AddressInput>;
};

export type UpdateQuestionnaireInput = {
  id: Scalars['ID'];
  contexts?: Maybe<Array<ContextInput>>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type UpdateSurveyInput = {
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  organizationalUnitId?: Maybe<Scalars['ID']>;
  type?: Maybe<QuestionnaireType>;
  questionnaireId?: Maybe<Scalars['ID']>;
  customQuestions?: Maybe<Array<QuestionInput>>;
  interviewees?: Maybe<Array<PersonInput>>;
  questionnairesCompleted?: Maybe<Scalars['Int']>;
  deadline?: Maybe<Scalars['Int']>;
  status?: Maybe<SurveyStatus>;
  published?: Maybe<Scalars['Int']>;
  finalized?: Maybe<Scalars['Int']>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  id: Scalars['ID'];
  type: UserType;
  name: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  ouTreeAccessAllowed?: Maybe<Array<Scalars['ID']>>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type UpdateUserQuestionnaireInput = {
  id: Scalars['ID'];
  type: QuestionnaireType;
  surveyId?: Maybe<Scalars['ID']>;
  userToken?: Maybe<Scalars['ID']>;
  questionnaireId?: Maybe<Scalars['ID']>;
  questionnaire?: Maybe<UpdateQuestionnaireInput>;
  customQuestions?: Maybe<Array<QuestionInput>>;
  status?: Maybe<UserQuestionnaireStatus>;
  published?: Maybe<Scalars['Int']>;
  answers?: Maybe<Array<UpdateAnswerInput>>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  type: UserType;
  name: Scalars['String'];
  email: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  ouTreeAccessAllowed?: Maybe<Array<Scalars['ID']>>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  items?: Maybe<Array<Maybe<User>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type UserQuestionnaire = {
  __typename?: 'UserQuestionnaire';
  id: Scalars['ID'];
  type: QuestionnaireType;
  surveyId: Scalars['ID'];
  userToken: Scalars['ID'];
  questionnaireId: Scalars['ID'];
  questionnaire?: Maybe<Questionnaire>;
  customQuestions?: Maybe<Array<Question>>;
  status: UserQuestionnaireStatus;
  published?: Maybe<Scalars['Int']>;
  answers?: Maybe<Array<Answer>>;
  created?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['Int']>;
  updatedBy?: Maybe<Scalars['String']>;
};

export type UserQuestionnaireConnection = {
  __typename?: 'UserQuestionnaireConnection';
  items?: Maybe<Array<Maybe<UserQuestionnaire>>>;
  nextToken?: Maybe<Scalars['String']>;
};

export enum UserQuestionnaireStatus {
  Published = 'published',
  Private = 'private'
}

export enum UserType {
  Su = 'SU',
  Be = 'BE'
}

export type ListOrganizationalUnitsQueryVariables = {
  type?: Maybe<OuType>;
};


export type ListOrganizationalUnitsQuery = (
  { __typename?: 'Query' }
  & { listOrganizationalUnits?: Maybe<(
    { __typename?: 'OrganizationalUnitConnection' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'OrganizationalUnit' }
      & { organizationalUnits?: Maybe<(
        { __typename?: 'OrganizationalUnitConnection' }
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'OrganizationalUnit' }
          & { organizationalUnits?: Maybe<(
            { __typename?: 'OrganizationalUnitConnection' }
            & { items?: Maybe<Array<Maybe<(
              { __typename?: 'OrganizationalUnit' }
              & { surveys?: Maybe<(
                { __typename?: 'SurveyConnection' }
                & { items?: Maybe<Array<Maybe<(
                  { __typename?: 'Survey' }
                  & SurveyFieldsFragment
                )>>> }
              )> }
              & OrganizationalUnitFieldsFragment
            )>>> }
          )> }
          & OrganizationalUnitFieldsFragment
        )>>> }
      )> }
      & OrganizationalUnitFieldsFragment
    )>>> }
  )> }
);

export type ListOrganizationalUnitsByIdQueryVariables = {
  id: Scalars['ID'];
};


export type ListOrganizationalUnitsByIdQuery = (
  { __typename?: 'Query' }
  & { listOrganizationalUnits?: Maybe<(
    { __typename?: 'OrganizationalUnitConnection' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'OrganizationalUnit' }
      & { organizationalUnits?: Maybe<(
        { __typename?: 'OrganizationalUnitConnection' }
        & { items?: Maybe<Array<Maybe<(
          { __typename?: 'OrganizationalUnit' }
          & { organizationalUnits?: Maybe<(
            { __typename?: 'OrganizationalUnitConnection' }
            & { items?: Maybe<Array<Maybe<(
              { __typename?: 'OrganizationalUnit' }
              & { surveys?: Maybe<(
                { __typename?: 'SurveyConnection' }
                & { items?: Maybe<Array<Maybe<(
                  { __typename?: 'Survey' }
                  & SurveyFieldsFragment
                )>>> }
              )> }
              & OrganizationalUnitFieldsFragment
            )>>> }
          )> }
          & OrganizationalUnitFieldsFragment
        )>>> }
      )> }
      & OrganizationalUnitFieldsFragment
    )>>> }
  )> }
);

export type GetAuLQueryVariables = {
  id: Scalars['ID'];
};


export type GetAuLQuery = (
  { __typename?: 'Query' }
  & { getOrganizationalUnit?: Maybe<(
    { __typename?: 'OrganizationalUnit' }
    & { organizationalUnits?: Maybe<(
      { __typename?: 'OrganizationalUnitConnection' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'OrganizationalUnit' }
        & { organizationalUnits?: Maybe<(
          { __typename?: 'OrganizationalUnitConnection' }
          & { items?: Maybe<Array<Maybe<(
            { __typename?: 'OrganizationalUnit' }
            & { surveys?: Maybe<(
              { __typename?: 'SurveyConnection' }
              & { items?: Maybe<Array<Maybe<(
                { __typename?: 'Survey' }
                & SurveyFieldsFragment
              )>>> }
            )> }
            & OrganizationalUnitFieldsFragment
          )>>> }
        )> }
        & OrganizationalUnitFieldsFragment
      )>>> }
    )> }
    & OrganizationalUnitFieldsFragment
  )> }
);

export type GetCompanyQueryVariables = {
  id: Scalars['ID'];
};


export type GetCompanyQuery = (
  { __typename?: 'Query' }
  & { getOrganizationalUnit?: Maybe<(
    { __typename?: 'OrganizationalUnit' }
    & { organizationalUnits?: Maybe<(
      { __typename?: 'OrganizationalUnitConnection' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'OrganizationalUnit' }
        & { surveys?: Maybe<(
          { __typename?: 'SurveyConnection' }
          & { items?: Maybe<Array<Maybe<(
            { __typename?: 'Survey' }
            & SurveyFieldsFragment
          )>>> }
        )> }
        & OrganizationalUnitFieldsFragment
      )>>> }
    )> }
    & OrganizationalUnitFieldsFragment
  )> }
);

export type GetDepartmentQueryVariables = {
  id: Scalars['ID'];
};


export type GetDepartmentQuery = (
  { __typename?: 'Query' }
  & { getOrganizationalUnit?: Maybe<(
    { __typename?: 'OrganizationalUnit' }
    & { surveys?: Maybe<(
      { __typename?: 'SurveyConnection' }
      & { items?: Maybe<Array<Maybe<(
        { __typename?: 'Survey' }
        & SurveyFieldsFragment
      )>>> }
    )> }
    & OrganizationalUnitFieldsFragment
  )> }
);

export type SurveyFieldsFragment = (
  { __typename?: 'Survey' }
  & Pick<Survey, 'id' | 'label' | 'organizationalUnitId' | 'type' | 'questionnaireId' | 'questionnairesCompleted' | 'deadline' | 'status' | 'published' | 'finalized' | 'created'>
  & { interviewees?: Maybe<Array<(
    { __typename?: 'Person' }
    & Pick<Person, 'role' | 'firstName' | 'lastName' | 'eMail'>
  )>>, answers?: Maybe<(
    { __typename?: 'AnswerConnection' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Answer' }
      & Pick<Answer, 'userToken'>
    )>>> }
  )> }
);

export type GetOrganizationalUnitQueryVariables = {
  id: Scalars['ID'];
};


export type GetOrganizationalUnitQuery = (
  { __typename?: 'Query' }
  & { getOrganizationalUnit?: Maybe<(
    { __typename?: 'OrganizationalUnit' }
    & OrganizationalUnitFieldsFragment
  )> }
);

export type CreateOrganizationalUnitMutationVariables = {
  input: CreateOrganizationalUnitInput;
};


export type CreateOrganizationalUnitMutation = (
  { __typename?: 'Mutation' }
  & { createOrganizationalUnit?: Maybe<(
    { __typename?: 'OrganizationalUnit' }
    & OrganizationalUnitFieldsFragment
  )> }
);

export type UpdateOrganizationalUnitMutationVariables = {
  input: UpdateOrganizationalUnitInput;
};


export type UpdateOrganizationalUnitMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganizationalUnit?: Maybe<(
    { __typename?: 'OrganizationalUnit' }
    & OrganizationalUnitFieldsFragment
  )> }
);

export type DeleteOrganizationalUnitMutationVariables = {
  input: DeleteOrganizationalUnitInput;
};


export type DeleteOrganizationalUnitMutation = (
  { __typename?: 'Mutation' }
  & { deleteOrganizationalUnit?: Maybe<(
    { __typename?: 'OrganizationalUnit' }
    & OrganizationalUnitFieldsFragment
  )> }
);

export type OrganizationalUnitFieldsFragment = (
  { __typename?: 'OrganizationalUnit' }
  & Pick<OrganizationalUnit, 'id' | 'parentId' | 'type' | 'name'>
  & { contact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'contactPerson' | 'eMail' | 'phone' | 'fax' | 'webUrls'>
  )>, address?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'addressLine1' | 'addressLine2' | 'postalCode' | 'city' | 'province' | 'country'>
  )> }
);

export type CreateSurveyMutationVariables = {
  input: CreateSurveyInput;
};


export type CreateSurveyMutation = (
  { __typename?: 'Mutation' }
  & { createSurvey?: Maybe<(
    { __typename?: 'Survey' }
    & Pick<Survey, 'id'>
  )> }
);

export type UpdateSurveyMutationVariables = {
  input: UpdateSurveyInput;
};


export type UpdateSurveyMutation = (
  { __typename?: 'Mutation' }
  & { updateSurvey?: Maybe<(
    { __typename?: 'Survey' }
    & Pick<Survey, 'id'>
  )> }
);

export type DeleteSurveyMutationVariables = {
  input: DeleteSurveyInput;
};


export type DeleteSurveyMutation = (
  { __typename?: 'Mutation' }
  & { deleteSurvey?: Maybe<(
    { __typename?: 'Survey' }
    & Pick<Survey, 'id'>
  )> }
);

export type QuerySurveysByOrganizationalUnitIdQueryVariables = {
  organizationalUnitId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
};


export type QuerySurveysByOrganizationalUnitIdQuery = (
  { __typename?: 'Query' }
  & { querySurveysByOrganizationalUnitId?: Maybe<(
    { __typename?: 'SurveyConnection' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'Survey' }
      & Pick<Survey, 'id' | 'label' | 'organizationalUnitId' | 'deadline' | 'status' | 'published' | 'finalized'>
    )>>> }
  )> }
);

export type GetSurveyQueryVariables = {
  id: Scalars['ID'];
};


export type GetSurveyQuery = (
  { __typename?: 'Query' }
  & { getSurvey?: Maybe<(
    { __typename?: 'Survey' }
    & Pick<Survey, 'id' | 'label' | 'organizationalUnitId' | 'type' | 'questionnaireId' | 'questionnairesCompleted' | 'deadline' | 'status' | 'published' | 'finalized' | 'created' | 'createdBy' | 'updated' | 'updatedBy'>
    & { customQuestions?: Maybe<Array<(
      { __typename?: 'Question' }
      & Pick<Question, 'id' | 'type' | 'question'>
      & { optionSet?: Maybe<Array<Maybe<(
        { __typename?: 'Option' }
        & Pick<Option, 'id' | 'label'>
      )>>> }
    )>>, interviewees?: Maybe<Array<(
      { __typename?: 'Person' }
      & Pick<Person, 'eMail' | 'firstName' | 'lastName' | 'role'>
    )>> }
  )> }
);

export type ListUserQuestionnairesQueryVariables = {
  filter?: Maybe<TableUserQuestionnaireFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type ListUserQuestionnairesQuery = (
  { __typename?: 'Query' }
  & { listUserQuestionnaires?: Maybe<(
    { __typename?: 'UserQuestionnaireConnection' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'UserQuestionnaire' }
      & Pick<UserQuestionnaire, 'id'>
    )>>> }
  )> }
);

export type GetUserQueryVariables = {
  id: Scalars['ID'];
};


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser?: Maybe<(
    { __typename?: 'User' }
    & UserFieldsFragment
  )> }
);

export type ListUsersQueryVariables = {
  filter?: Maybe<TableUserFilterInput>;
  limit?: Maybe<Scalars['Int']>;
  nextToken?: Maybe<Scalars['String']>;
};


export type ListUsersQuery = (
  { __typename?: 'Query' }
  & { listUsers?: Maybe<(
    { __typename?: 'UserConnection' }
    & { items?: Maybe<Array<Maybe<(
      { __typename?: 'User' }
      & UserFieldsFragment
    )>>> }
  )> }
);

export type CreateUserMutationVariables = {
  input: CreateUserInput;
};


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type UpdateUserMutationVariables = {
  input: UpdateUserInput;
};


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type DeleteUserMutationVariables = {
  input: DeleteUserInput;
};


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type UserFieldsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'type' | 'name' | 'email' | 'phone' | 'ouTreeAccessAllowed'>
);

export const SurveyFieldsFragmentDoc = gql`
    fragment surveyFields on Survey {
  id
  label
  organizationalUnitId
  type
  questionnaireId
  interviewees {
    role
    firstName
    lastName
    eMail
  }
  questionnairesCompleted
  deadline
  status
  published
  finalized
  created
  answers(limit: 2000) {
    items {
      userToken
    }
  }
}
    `;
export const OrganizationalUnitFieldsFragmentDoc = gql`
    fragment organizationalUnitFields on OrganizationalUnit {
  id
  parentId
  type
  name
  contact {
    contactPerson
    eMail
    phone
    fax
    webUrls
  }
  address {
    addressLine1
    addressLine2
    postalCode
    city
    province
    country
  }
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment userFields on User {
  id
  type
  name
  email
  phone
  ouTreeAccessAllowed
}
    `;
export const ListOrganizationalUnitsDocument = gql`
    query ListOrganizationalUnits($type: OUType) {
  listOrganizationalUnits(filter: {type: {eq: $type}}, limit: 100) {
    items {
      ...organizationalUnitFields
      organizationalUnits(limit: 100) {
        items {
          ...organizationalUnitFields
          organizationalUnits(limit: 100) {
            items {
              ...organizationalUnitFields
              surveys(limit: 100) {
                items {
                  ...surveyFields
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${OrganizationalUnitFieldsFragmentDoc}
${SurveyFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListOrganizationalUnitsGQL extends Apollo.Query<ListOrganizationalUnitsQuery, ListOrganizationalUnitsQueryVariables> {
    document = ListOrganizationalUnitsDocument;
    
  }
export const ListOrganizationalUnitsByIdDocument = gql`
    query ListOrganizationalUnitsByID($id: ID!) {
  listOrganizationalUnits(filter: {id: {eq: $id}}, limit: 100) {
    items {
      ...organizationalUnitFields
      organizationalUnits(limit: 100) {
        items {
          ...organizationalUnitFields
          organizationalUnits(limit: 100) {
            items {
              ...organizationalUnitFields
              surveys(limit: 100) {
                items {
                  ...surveyFields
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${OrganizationalUnitFieldsFragmentDoc}
${SurveyFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListOrganizationalUnitsByIdGQL extends Apollo.Query<ListOrganizationalUnitsByIdQuery, ListOrganizationalUnitsByIdQueryVariables> {
    document = ListOrganizationalUnitsByIdDocument;
    
  }
export const GetAuLDocument = gql`
    query GetAuL($id: ID!) {
  getOrganizationalUnit(id: $id) {
    ...organizationalUnitFields
    organizationalUnits(limit: 100) {
      items {
        ...organizationalUnitFields
        organizationalUnits(limit: 100) {
          items {
            ...organizationalUnitFields
            surveys(limit: 100) {
              items {
                ...surveyFields
              }
            }
          }
        }
      }
    }
  }
}
    ${OrganizationalUnitFieldsFragmentDoc}
${SurveyFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAuLGQL extends Apollo.Query<GetAuLQuery, GetAuLQueryVariables> {
    document = GetAuLDocument;
    
  }
export const GetCompanyDocument = gql`
    query GetCompany($id: ID!) {
  getOrganizationalUnit(id: $id) {
    ...organizationalUnitFields
    organizationalUnits(limit: 100) {
      items {
        ...organizationalUnitFields
        surveys(limit: 100) {
          items {
            ...surveyFields
          }
        }
      }
    }
  }
}
    ${OrganizationalUnitFieldsFragmentDoc}
${SurveyFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCompanyGQL extends Apollo.Query<GetCompanyQuery, GetCompanyQueryVariables> {
    document = GetCompanyDocument;
    
  }
export const GetDepartmentDocument = gql`
    query GetDepartment($id: ID!) {
  getOrganizationalUnit(id: $id) {
    ...organizationalUnitFields
    surveys(limit: 100) {
      items {
        ...surveyFields
      }
    }
  }
}
    ${OrganizationalUnitFieldsFragmentDoc}
${SurveyFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDepartmentGQL extends Apollo.Query<GetDepartmentQuery, GetDepartmentQueryVariables> {
    document = GetDepartmentDocument;
    
  }
export const GetOrganizationalUnitDocument = gql`
    query GetOrganizationalUnit($id: ID!) {
  getOrganizationalUnit(id: $id) {
    ...organizationalUnitFields
  }
}
    ${OrganizationalUnitFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetOrganizationalUnitGQL extends Apollo.Query<GetOrganizationalUnitQuery, GetOrganizationalUnitQueryVariables> {
    document = GetOrganizationalUnitDocument;
    
  }
export const CreateOrganizationalUnitDocument = gql`
    mutation CreateOrganizationalUnit($input: CreateOrganizationalUnitInput!) {
  createOrganizationalUnit(input: $input) {
    ...organizationalUnitFields
  }
}
    ${OrganizationalUnitFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOrganizationalUnitGQL extends Apollo.Mutation<CreateOrganizationalUnitMutation, CreateOrganizationalUnitMutationVariables> {
    document = CreateOrganizationalUnitDocument;
    
  }
export const UpdateOrganizationalUnitDocument = gql`
    mutation UpdateOrganizationalUnit($input: UpdateOrganizationalUnitInput!) {
  updateOrganizationalUnit(input: $input) {
    ...organizationalUnitFields
  }
}
    ${OrganizationalUnitFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateOrganizationalUnitGQL extends Apollo.Mutation<UpdateOrganizationalUnitMutation, UpdateOrganizationalUnitMutationVariables> {
    document = UpdateOrganizationalUnitDocument;
    
  }
export const DeleteOrganizationalUnitDocument = gql`
    mutation DeleteOrganizationalUnit($input: DeleteOrganizationalUnitInput!) {
  deleteOrganizationalUnit(input: $input) {
    ...organizationalUnitFields
  }
}
    ${OrganizationalUnitFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteOrganizationalUnitGQL extends Apollo.Mutation<DeleteOrganizationalUnitMutation, DeleteOrganizationalUnitMutationVariables> {
    document = DeleteOrganizationalUnitDocument;
    
  }
export const CreateSurveyDocument = gql`
    mutation CreateSurvey($input: CreateSurveyInput!) {
  createSurvey(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSurveyGQL extends Apollo.Mutation<CreateSurveyMutation, CreateSurveyMutationVariables> {
    document = CreateSurveyDocument;
    
  }
export const UpdateSurveyDocument = gql`
    mutation UpdateSurvey($input: UpdateSurveyInput!) {
  updateSurvey(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSurveyGQL extends Apollo.Mutation<UpdateSurveyMutation, UpdateSurveyMutationVariables> {
    document = UpdateSurveyDocument;
    
  }
export const DeleteSurveyDocument = gql`
    mutation DeleteSurvey($input: DeleteSurveyInput!) {
  deleteSurvey(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteSurveyGQL extends Apollo.Mutation<DeleteSurveyMutation, DeleteSurveyMutationVariables> {
    document = DeleteSurveyDocument;
    
  }
export const QuerySurveysByOrganizationalUnitIdDocument = gql`
    query QuerySurveysByOrganizationalUnitId($organizationalUnitId: ID!, $first: Int) {
  querySurveysByOrganizationalUnitId(organizationalUnitId: $organizationalUnitId, first: $first) {
    items {
      id
      label
      organizationalUnitId
      deadline
      status
      published
      finalized
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class QuerySurveysByOrganizationalUnitIdGQL extends Apollo.Query<QuerySurveysByOrganizationalUnitIdQuery, QuerySurveysByOrganizationalUnitIdQueryVariables> {
    document = QuerySurveysByOrganizationalUnitIdDocument;
    
  }
export const GetSurveyDocument = gql`
    query GetSurvey($id: ID!) {
  getSurvey(id: $id) {
    id
    label
    organizationalUnitId
    type
    questionnaireId
    customQuestions {
      id
      type
      question
      optionSet {
        id
        label
      }
    }
    interviewees {
      eMail
      firstName
      lastName
      role
    }
    questionnairesCompleted
    deadline
    status
    published
    finalized
    created
    createdBy
    updated
    updatedBy
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSurveyGQL extends Apollo.Query<GetSurveyQuery, GetSurveyQueryVariables> {
    document = GetSurveyDocument;
    
  }
export const ListUserQuestionnairesDocument = gql`
    query ListUserQuestionnaires($filter: TableUserQuestionnaireFilterInput, $limit: Int, $nextToken: String) {
  listUserQuestionnaires(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ListUserQuestionnairesGQL extends Apollo.Query<ListUserQuestionnairesQuery, ListUserQuestionnairesQueryVariables> {
    document = ListUserQuestionnairesDocument;
    
  }
export const GetUserDocument = gql`
    query GetUser($id: ID!) {
  getUser(id: $id) {
    ...userFields
  }
}
    ${UserFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserGQL extends Apollo.Query<GetUserQuery, GetUserQueryVariables> {
    document = GetUserDocument;
    
  }
export const ListUsersDocument = gql`
    query ListUsers($filter: TableUserFilterInput, $limit: Int, $nextToken: String) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      ...userFields
    }
  }
}
    ${UserFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ListUsersGQL extends Apollo.Query<ListUsersQuery, ListUsersQueryVariables> {
    document = ListUsersDocument;
    
  }
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUserGQL extends Apollo.Mutation<CreateUserMutation, CreateUserMutationVariables> {
    document = CreateUserDocument;
    
  }
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
  }
export const DeleteUserDocument = gql`
    mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteUserGQL extends Apollo.Mutation<DeleteUserMutation, DeleteUserMutationVariables> {
    document = DeleteUserDocument;
    
  }