import { Component } from '@angular/core';
import { SuiModal } from 'ng2-semantic-ui';
import {
  AddressInput,
  ContactInput,
  CreateOrganizationalUnitInput,
  OrganizationalUnit,
  OuType,
  UpdateOrganizationalUnitInput
} from '../../../shared/graphql/graphql-client.service';
import { GetOULabel } from '../../../../const.exports';

declare var $: any;

export interface IOrganizationalUnitEditContext {
  ou: OrganizationalUnit;
  idxPanelOpen: number;
  isSU: boolean;
}

@Component({
  selector: 'bkp-organizational-unit-edit',
  templateUrl: './organizational-unit-edit.component.html',
  styles: []
})
export class OrganizationalUnitEditComponent {

  $ = $;
  OuType = OuType;
  getOULabel = GetOULabel;

  editHeader: string;
  ouForm: CreateOrganizationalUnitInput | UpdateOrganizationalUnitInput;
  updating: boolean;

  removeOUConfirmEnabled = false;

  constructor(public modal: SuiModal<IOrganizationalUnitEditContext,
        CreateOrganizationalUnitInput | UpdateOrganizationalUnitInput | string, void>) {

    const ou = this.modal.context.ou;
    if (ou.id) {
      this.editHeader = `${this.getOULabel(ou.type)} "${ou.name}" bearbeiten`;
      this.ouForm = { ...ou } as UpdateOrganizationalUnitInput;
      this.updating = true;
    } else {
      const neu = ou.type === OuType.Company ? 'Neuen' : 'Neue';
      this.editHeader = `${neu} ${this.getOULabel(ou.type)} erstellen`;
      this.ouForm = {
        ...ou,
        contact: {} as ContactInput,
        address: {} as AddressInput
      } as CreateOrganizationalUnitInput;
      this.updating = false;
    }
    if (!this.ouForm.contact.webUrls || this.ouForm.contact.webUrls.length === 0) {
      this.ouForm.contact.webUrls = [];
    }
  }

  deleteOUEnabled() {
    return this.ouForm.type !== OuType.AuL || (this.ouForm.type === OuType.AuL && this.modal.context.isSU);
  }

  recheckDelete(id: string) {
    switch (this.ouForm.type) {
      case OuType.AuL:
        if (window.confirm('Wollen Sie diese Beratungseinheit wirklich löschen?' +
          '\n\nAchtung, damit löschen Sie auch alle Firmen, Abteilungen und Checks, die ggf. von anderen Personen' +
          ' in der Beratungseinheit angelegt wurden!')) {
          this.modal.approve(id);
        } else {
          this.modal.deny(undefined);
        }
        break;
      case OuType.Company:
        if (window.confirm('Wollen Sie diesen Betrieb wirklich löschen?' +
          '\n\nAchtung, damit löschen Sie auch alle Abteilungen und Checks, die ggf. von anderen Personen' +
          ' in diesem Betrieb angelegt wurden!')) {
          this.modal.approve(id);
        } else {
          this.modal.deny(undefined);
        }
        break;
      case OuType.Department:
        if (window.confirm('Wollen Sie diese Abteilung wirklich löschen?' +
          '\n\nAchtung, damit löschen Sie auch alle Checks, die ggf. von anderen Personen' +
          ' in der Abteilung angelegt wurden!')) {
          this.modal.approve(id);
        } else {
          this.modal.deny(undefined);
        }
        break;
    }
  }
}
