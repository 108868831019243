import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SuiModalService } from 'ng2-semantic-ui';
import { Apollo } from 'apollo-angular';

import {
  CreateOrganizationalUnitGQL,
  CreateOrganizationalUnitInput,
  CreateSurveyGQL,
  CreateSurveyInput,
  DeleteOrganizationalUnitGQL,
  DeleteSurveyGQL,
  GetAuLGQL,
  GetCompanyGQL,
  GetDepartmentGQL,
  GetOrganizationalUnitGQL,
  GetSurveyGQL,
  ListOrganizationalUnitsByIdGQL,
  ListOrganizationalUnitsGQL, Maybe,
  OrganizationalUnit,
  OuType, QuerySurveysByOrganizationalUnitIdGQL,
  Survey,
  SurveyStatus,
  UpdateOrganizationalUnitGQL,
  UpdateOrganizationalUnitInput,
  UpdateSurveyGQL,
  UpdateSurveyInput,
  UpdateUserGQL
} from '../../shared/graphql/graphql-client.service';

import { LocalStorageService } from '../../shared/local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { compareNamesFn, GetOULabel, GetQuestionnaireTypeLabel } from '../../../const.exports';
import { DataService } from '../../shared/data.service';
import { OrganizationalUnitEdit } from './organizational-unit-edit/organizational-unit-edit';
import { SurveyEdit } from '../../survey/components/survey-edit/survey-edit';
import { AuthService } from '../../shared/auth/auth.service';
import { UIService } from '../../shared/ui.service';

@Component({
  selector: 'bkp-organizational-unit-list',
  templateUrl: './organizational-unit-list.component.html',
  styles: [],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationalUnitListComponent implements OnInit, OnDestroy {

  private ouSubscription: Subscription;

  OuType = OuType;
  getOULabel = GetOULabel;
  getQuestionnaireTypeLabel = GetQuestionnaireTypeLabel;

  favorites: Array<OrganizationalUnit> = [];
  organizationalUnits: Array<OrganizationalUnit>;
  ousAllowed: Array<string>;
  loading: boolean;

  evaluation: Array<{ouId: string, surveys: any}> = [];

  panelOpenIds = [];

  ouMutationOptions = {
    refetchQueries: [{
      query: this.listOUGQL.document,
      variables: { type: OuType.AuL }
    }]
  };

  constructor(private route: ActivatedRoute,
              private router: Router,
              private apollo: Apollo,
              private listOUGQL: ListOrganizationalUnitsGQL,
              private listOUByIDGQL: ListOrganizationalUnitsByIdGQL,
              private createOUGQL: CreateOrganizationalUnitGQL,
              private updateOUGQL: UpdateOrganizationalUnitGQL,
              private deleteOrganizationalUnitGQL: DeleteOrganizationalUnitGQL,
              private getAuLGQL: GetAuLGQL,
              private getCompanyGQL: GetCompanyGQL,
              private getDepartmentGQL: GetDepartmentGQL,
              private getOrganizationalUnitGQL: GetOrganizationalUnitGQL,
              private createSurveyGQL: CreateSurveyGQL,
              private updateSurveyGQL: UpdateSurveyGQL,
              private querySurveysByOrganizationalUnitIdGQL: QuerySurveysByOrganizationalUnitIdGQL,
              private getSurveyGQL: GetSurveyGQL,
              private deleteSurveyGQL: DeleteSurveyGQL,
              private updateUserGQL: UpdateUserGQL,
              private modalService: SuiModalService,
              private localStorageService: LocalStorageService,
              private dataService: DataService,
              private uiService: UIService,
              public authService: AuthService) {
  }

  ngOnInit() {
    this.loading = true;
    this.ouSubscription = this.listOUGQL.watch({ type: OuType.AuL }).valueChanges
      .pipe(
        map((result: any) => result.data.listOrganizationalUnits.items),
        map((result: any) => result.sort(compareNamesFn)),
        tap((result: Array<OrganizationalUnit>) => {
          this.organizationalUnits = result;
          this.ousAllowed = this.authService.getBKPUser().ouTreeAccessAllowed;
          if (!this.authService.isSU()) {
            this.organizationalUnits = this.organizationalUnits.filter(ou => this.ousAllowed.includes(ou.id));
          }
          this.loading = false;
        })
      )
      .subscribe();

    // Nice-to-have: OU favorites
    // const favoritesObj = this.localStorageService.getAsObject('favorites');
    // if (favoritesObj && favoritesObj.length > 0) {
    //   this.favorites = favoritesObj.map((favorite: { ouId: string, type: OuType }) => {
    //     if (favorite.type === OuType.Company) {
    //       getCompanyGQL.fetch({ id: favorite.ouId }).subscribe(({ data, loading }) => {
    //         return data.getOrganizationalUnit;
    //       });
    //     } else {
    //       getDepartmentGQL.fetch({ id: favorite.ouId }).subscribe(({ data, loading }) => {
    //         return data.getOrganizationalUnit;
    //       });
    //     }
    //   });
    // } else {
    //   this.favorites = [];
    // }
  }

  addOU(parentId: string, type: OuType) {
    this.modalService
      .open(new OrganizationalUnitEdit({ parentId, type } as OrganizationalUnit, 0, this.authService.isSU()))
      .onApprove((result: unknown) => {
        const data = result as CreateOrganizationalUnitInput;
        this.createOUGQL.mutate({
          input: data
        }, this.ouMutationOptions).subscribe(createOUGQLResult => {
          if (data.type === OuType.AuL) {
            const user = this.authService.getBKPUser();
            const userInput = {
              id: user.id,
              type: user.type,
              name: user.name,
              email: user.email,
              ouTreeAccessAllowed: [...user.ouTreeAccessAllowed, createOUGQLResult.data.createOrganizationalUnit.id],
              updated: this.dataService.getTimestamp(),
              updatedBy: this.authService.getUsername(),
            };
            this.updateUserGQL.mutate({
              input: userInput
            }, this.ouMutationOptions).subscribe(async updateUserGQLValueResult => {
              await this.authService.loadBKPUser();
              this.uiService.showToast(`${this.getOULabel(data.type)} "${data.name}" erstellt und dem aktuellen Nutzer zugeordnet.`);
            }, error => {
              this.uiService.showErrorToast(
                `Fehler beim Zuordnen der Organisationseinheit zum aktuellen Nutzer!`);
            });
          } else {
            this.uiService.showToast(`${this.getOULabel(data.type)} "${data.name}" erstellt.`);
          }
        }, error => {
          this.uiService.showErrorToast(
            `Fehler beim Erstellen der Organisationseinheit (${this.getOULabel(data.type)}) "${data.name}"!`);
        });
      })
      .onDeny((result) => {
      });
  }

  editOU(ouId: string, idxPanelOpen: number) {
    this.getOrganizationalUnitGQL.fetch({ id: ouId }, { fetchPolicy: 'no-cache' }).subscribe(fetchResult => {
      const ou = fetchResult.data.getOrganizationalUnit;
      delete ou.__typename;
      delete ou.address.__typename;
      delete ou.contact.__typename;

      this.modalService
        .open(new OrganizationalUnitEdit(ou, idxPanelOpen, this.authService.isSU()))
        .onApprove((result: unknown) => {
          if (typeof result === 'string') {
            this.deleteOrganizationalUnitGQL.mutate({
              input: { id: result as string }
            }, this.ouMutationOptions).subscribe(value => {
              this.uiService.showToast(`Organisationseinheit gelöscht.`);
            }, error => {
              this.uiService.showErrorToast(`Fehler beim Löschen der Organisationseinheit!`);
            });
          } else {
            const data = result as UpdateOrganizationalUnitInput;
            this.updateOUGQL.mutate({
              input: data
            }, this.ouMutationOptions).subscribe(value => {
              this.uiService.showToast(`${this.getOULabel(data.type)} "${data.name}" geändert.`);
            }, error => {
              this.uiService.showErrorToast(
                `Fehler beim Ändern der Organisationseinheit (${this.getOULabel(data.type)}) "${data.name}"!`);
            });
          }
        })
        .onDeny((result) => {
        });
    });
  }

  private reduceName(s: string): string {
    s = s.replace(/[^ a-zA-Z0-9äöüÄÖÜ]+/g, '');
    let words = s.split(' ');
    words = words.filter(word => word && word.length > 2);
    words = words.map(word => word.substr(0, 3));
    return words.join('-');
  }

  // private filterName(s: string): string {
  //   s = s.replace(/[^a-zA-Z0-9]+/g, '');
  //   let words = s.split(' ');
  //   words = words.filter(word => word && word.length > 2);
  //   words = words.map(word => word.substr(0, 3));
  //   return words.join('-');
  // }

  private buildSurveyId(ouId: string): string {
    let company = '';
    let department = '';
    this.organizationalUnits.forEach((aulOU => {
      if (aulOU.organizationalUnits && aulOU.organizationalUnits.items) {
        aulOU.organizationalUnits.items.forEach(companyOU => {
          if (companyOU.organizationalUnits && companyOU.organizationalUnits.items) {
            companyOU.organizationalUnits.items.forEach(departmentOU => {
              if (departmentOU.id === ouId) {
                company = this.reduceName(companyOU.name);
                department = this.reduceName(departmentOU.name);
              }
            });
          }
        });
      }
    }));

    return `${company}_${department}_${this.dataService.getDateBackwardsFormatted()}`;
  }

  addSurvey(ouId: string) {
    this.modalService
      .open(new SurveyEdit({
        id: this.buildSurveyId(ouId), // TODO: Auf doppelte IDs prüfen
        organizationalUnitId: ouId
      } as Survey))
      .onApprove((result: unknown) => {
        const survey = result as CreateSurveyInput;
        // survey.organizationalUnitId = ouId;
        survey.status = SurveyStatus.Inactive;
        survey.created = 0; // Overwritten by AppSync createSurvey Resolver with current timestamp!
        survey.createdBy = this.authService.getUsername();

        this.createSurveyGQL.mutate({
          input: survey
        }, this.ouMutationOptions).subscribe(value => {
          this.uiService.showToast(`${this.getQuestionnaireTypeLabel(survey.type)} "${survey.label}" erstellt.`);
        }, error => {
          this.uiService.showErrorToast(
            `Fehler beim Erstellen der Umfrage (${this.getQuestionnaireTypeLabel(survey.type)}) "${survey.label}"!`);
        });
      })
      .onDeny((result) => {
      });
  }

  editSurvey(surveyId: string) {
    this.getSurveyGQL.fetch({ id: surveyId }, { fetchPolicy: 'no-cache' }).subscribe(fetchResult => {
      const survey = fetchResult.data.getSurvey as Survey;
      delete survey.__typename;

      this.modalService
        .open(new SurveyEdit(survey))
        .onApprove((result: unknown) => {
          if (typeof result === 'string') {
            this.deleteSurveyGQL.mutate({
              input: { id: result as string }
            }, this.ouMutationOptions).subscribe(value => {
              this.uiService.showToast(`Umfrage gelöscht.`);
            }, error => {
              this.uiService.showErrorToast(`Fehler beim Löschen der Umfrage!`);
            });
          } else {
            let surveyInput = result as UpdateSurveyInput;
            surveyInput.updated = this.dataService.getTimestamp();
            surveyInput.updatedBy = this.authService.getUsername();
            surveyInput = this.dataService.removeTypename(surveyInput);
            this.updateSurveyGQL.mutate({
              input: surveyInput
            }, this.ouMutationOptions).subscribe(value => {
              this.uiService.showToast(`${this.getQuestionnaireTypeLabel(surveyInput.type)} "${surveyInput.label}" geändert.`);
            }, error => {
              this.uiService.showErrorToast(
                `Fehler beim Ändern der Umfrage (${this.getQuestionnaireTypeLabel(surveyInput.type)}) "${surveyInput.label}"!`);
            });
          }
        })
        .onDeny((result) => {
        });
    });
  }

  publishSurvey(survey: Survey) {
    this.updateSurveyGQL.mutate({
      input: {
        id: survey.id,
        status: survey.status,
        published: survey.published,
        updated: this.dataService.getTimestamp(),
        updatedBy: 'PublishSurvey'
      }
    }, this.ouMutationOptions).subscribe(value => {
      this.uiService.showToast(`${this.getQuestionnaireTypeLabel(survey.type)} "${survey.label}" veröffentlicht.`);
    }, error => {
      this.uiService.showErrorToast(
        `Fehler beim Veröffentlichen der Umfrage (${this.getQuestionnaireTypeLabel(survey.type)}) "${survey.label}"!`);
    });
  }

  abortSurvey(survey: Survey) {
    this.updateSurveyGQL.mutate({
      input: {
        id: survey.id,
        status: survey.status,
        finalized: survey.finalized,
        updated: this.dataService.getTimestamp(),
        updatedBy: 'AbortSurvey'
      }
    }, this.ouMutationOptions).subscribe(value => {
      this.uiService.showToast(`${this.getQuestionnaireTypeLabel(survey.type)} "${survey.label}" beendet.`);
    }, error => {
      this.uiService.showErrorToast(
        `Fehler beim Beenden der Umfrage (${this.getQuestionnaireTypeLabel(survey.type)}) "${survey.label}"!`);
    });
  }

  loadEvaluation(ouId: string) {
    const evalItem = this.evaluation.find((item) => item.ouId === ouId);
    if (!evalItem) {
      this.querySurveysByOrganizationalUnitIdGQL.fetch({ organizationalUnitId: ouId, first: 1000 }).subscribe(fetchResult => {
        this.evaluation.push({ ouId, surveys: fetchResult.data.querySurveysByOrganizationalUnitId.items });
      });
    }
  }

  addFavorite(event: { ouId: string, type: OuType }) {
    let favoritesArr = this.localStorageService.getAsObject('favorites');
    favoritesArr = favoritesArr ? [...favoritesArr, event] : [event];
    this.localStorageService.setAsObject('favorites', favoritesArr);
  }

  panelOpenChanged(ouId: string) {
    const idx = this.panelOpenIds.indexOf(ouId);
    if (idx !== -1) {
      this.panelOpenIds.splice(idx, 1);
    } else {
      this.panelOpenIds.push(ouId);
    }
  }

  ngOnDestroy() {
    if (this.ouSubscription) {
      this.ouSubscription.unsubscribe();
    }
  }

}
