import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuestionnaireType, Survey, SurveyStatus } from '../../../shared/graphql/graphql-client.service';
import { DataService } from '../../../shared/data.service';
import { SurveyUrl } from '../../../../const.exports';

@Component({
  selector: 'bkp-survey',
  templateUrl: './survey.component.html',
  styles: [`
    .line {
      margin-right: 2em;
      display: inline-block;
    }
  `]
})
export class SurveyComponent implements OnInit {

  @Input() survey: Survey;
  @Output() editSurveyEvent = new EventEmitter<string>();
  @Output() publishSurveyEvent = new EventEmitter<Survey>();
  @Output() abortSurveyEvent = new EventEmitter<Survey>();

  SurveyStatus = SurveyStatus;
  SurveyUrl = SurveyUrl;

  header: string;
  questionnairesCompletedCount: number;
  intervieweeRoleCount: number;
  published: string;
  finalized: string;
  deadline: string;
  created: string;
  linkEnabled: boolean;

  constructor(private dataService: DataService) {}

  ngOnInit() {
    if (this.survey.type === QuestionnaireType.SelbstCheck ) {
      this.header = 'SelbstCheck';
    } else if (this.survey.type === QuestionnaireType.FremdCheckDv) {
      this.header = 'FremdCheck für direkte Vorgesetzte';
    } else if (this.survey.type === QuestionnaireType.FremdCheckPa) {
      this.header = 'FremdCheck für Personalabteilung';
    } else if (this.survey.type === QuestionnaireType.FremdCheckBi) {
      this.header = 'FremdCheck für betriebliche Interessenvertretung';
    } else if (this.survey.type === QuestionnaireType.BetriebsCheck) {
      this.header = 'BetriebsCheck';
    }

    this.questionnairesCompletedCount = this.survey.questionnairesCompleted ? this.survey.questionnairesCompleted : 0;
    this.intervieweeRoleCount = this.survey.interviewees ? this.survey.interviewees.length : 0;

    this.published = (this.survey.published && this.survey.status === SurveyStatus.Active)
      ? this.dataService.getDateFormatted(this.survey.published) : '-' ;
    this.finalized = this.survey.finalized ? this.dataService.getDateFormatted(this.survey.finalized) : '-' ;
    this.deadline = this.survey.deadline ? this.dataService.getDateFormatted(this.survey.deadline) : '-' ;
    this.created = this.survey.created ? this.dataService.getDateFormatted(this.survey.created) : '-' ;
  }

  toggleLinkView() {
    this.linkEnabled = !this.linkEnabled;
  }

  publishSurvey() {
    this.survey.status = SurveyStatus.Active;
    this.survey.published = this.dataService.getTimestamp();
    this.published = this.dataService.getDateFormatted(this.survey.published);
    this.publishSurveyEvent.emit(this.survey);
  }

  abortSurvey() {
    this.survey.status = SurveyStatus.Inactive;
    this.survey.finalized = this.dataService.getTimestamp();
    this.abortSurveyEvent.emit(this.survey);
  }
}
