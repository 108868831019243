import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyComponent } from './components/survey/survey.component';
import { SurveyEditComponent } from './components/survey-edit/survey-edit.component';
import { FormsModule } from '@angular/forms';
import {
  SuiAccordionModule,
  SuiDatepickerModule,
  SuiDropdownModule,
  SuiPopupModule,
  SuiSelectModule
} from 'ng2-semantic-ui';
import { IntervieweeComponent } from './components/interviewee/interviewee.component';
import { CustomQuestionComponent } from './components/custom-question/custom-question.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SuiAccordionModule,
    SuiDropdownModule,
    SuiSelectModule,
    SuiDatepickerModule,
    SuiPopupModule
  ],
  declarations: [SurveyComponent, SurveyEditComponent, IntervieweeComponent, CustomQuestionComponent],
  exports: [SurveyComponent],
  entryComponents: [SurveyEditComponent]
})
export class SurveyModule { }
