import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SuiDropdownModule, SuiModule } from 'ng2-semantic-ui';

import { DataService } from './data.service';
import { LocalStorageService } from './local-storage.service';
import { LoggingService } from './logging.service';

import { LoggingComponent } from './logging/logging.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { AuthComponent } from './auth/auth.component';

const parts = [AuthComponent, FooterComponent, HeaderComponent, LoggingComponent];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SuiModule,
    SuiDropdownModule
  ],
  declarations: [...parts],
  exports: [...parts]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [DataService, LocalStorageService, LoggingService]
    };
  }
}
