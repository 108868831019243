import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Option, QuestionInput, QuestionType } from '../../../shared/graphql/graphql-client.service';

@Component({
  selector: 'bkp-custom-question',
  templateUrl: './custom-question.component.html',
  styles: [`
    .inputElemGroup {
        margin-top: 1em;
        width: 100%;
        /*text-align: center*/
    }
    .inlineElem {
        display: inline-block;
        width: 100%;
    }
  `]
})
export class CustomQuestionComponent {

  @Input() customQuestion: QuestionInput;
  @Output() removeCustomQuestionEvent = new EventEmitter();

  QuestionType = QuestionType;

  getQuestionTypeLabel(questionType: QuestionType) {
    switch (questionType) {
      case QuestionType.TextInput:
        return 'Textfeld';
      case QuestionType.SingleChoice:
        return 'Einfachauswahl';
      // case QuestionType.MultipleChoice:
      //   return 'Mehrfachauswahl';
    }
  }

  setQuestionType(questionType: QuestionType) {
    this.customQuestion.type = questionType;
  }

  isChoice() {
    return this.customQuestion.type === QuestionType.SingleChoice;
      // || this.customQuestion.type === QuestionType.MultipleChoice;
  }

  hasOptionSet() {
    return this.customQuestion.optionSet && this.customQuestion.optionSet.length > 0;
  }

  addAnswerChoice() {
    const option = {} as Option;

    if (this.hasOptionSet()) {
      option.id = this.customQuestion.optionSet[this.customQuestion.optionSet.length - 1].id + 1;
      this.customQuestion.optionSet = [...this.customQuestion.optionSet, option];
    } else {
      option.id = 1;
      this.customQuestion.optionSet = [option];
    }
  }

  removeAnswerChoice(id: number) {
    this.customQuestion.optionSet.splice(id - 1, 1);
    id = 1;
    this.customQuestion.optionSet.forEach((o: Option) => { o.id = id++; });
  }

}
