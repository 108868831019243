import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { OrganizationalUnit, Survey, SurveyStatus } from '../../shared/graphql/graphql-client.service';
import { DataService } from '../../shared/data.service';
import { DeadlineIdParam, EvaluationUrl, NavLabel, OUIdParam } from '../../../const.exports';

interface SurveyEvaluationItem {
  deadline: number;
  deadlineFormatted: string;
  label: string;
}

@Component({
  selector: 'bkp-evaluation-overview',
  templateUrl: './evaluation-overview.component.html',
  styles: []
})
export class EvaluationOverviewComponent implements OnInit, OnChanges {

  @Input() ou: OrganizationalUnit;
  @Input() surveys: Array<Survey>;

  currentEvaluations: SurveyEvaluationItem[] = [];
  archivedEvaluations: SurveyEvaluationItem[] = [];

  constructor(private router: Router, private dataService: DataService) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.surveys = this.surveys.sort((a, b) => {
      if (a.deadline < b.deadline) {
        return -1;
      } else if (a.deadline > b.deadline) {
        return 1;
      } else {
        return 0;
      }
    });
    this.surveys.forEach((survey) => {
      const deadlineFormatted = this.dataService.getDateFormatted(survey.deadline);
      if (survey.status === SurveyStatus.Inactive && survey.published && survey.finalized) {
        const idx = this.archivedEvaluations.findIndex(item => item.deadline === survey.deadline);
        if (idx === -1) {
          this.archivedEvaluations = [ ...this.archivedEvaluations, { deadline: survey.deadline, deadlineFormatted, label: survey.label } ];
        } else {
          const currEval = this.archivedEvaluations[idx];
          currEval.label += ', ' + survey.label;
        }
      } else {
        const idx = this.currentEvaluations.findIndex(item => item.deadline === survey.deadline);
        if (idx === -1) {
          this.currentEvaluations = [ ...this.currentEvaluations, { deadline: survey.deadline, deadlineFormatted, label: survey.label } ];
        } else {
          const currEval = this.currentEvaluations[idx];
          currEval.label += ', ' + survey.label;
        }
      }
    });
  }

  evalSurveyExternal(deadline: number) {
    window.open(`${EvaluationUrl}/?${OUIdParam}=${this.ou.id},${DeadlineIdParam}=${deadline}`, '_blank');
  }

  async evalSurveyInternal(deadline: number) {
    await this.router.navigate([NavLabel.EvalSurvey], {
      queryParams: {
        [OUIdParam]: this.ou.id,
        [DeadlineIdParam]: deadline
      }
    });
  }

}
