import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PersonInput } from '../../../shared/graphql/graphql-client.service';
import { PersonRoles } from '../../../../const.exports';

@Component({
  selector: 'bkp-interviewee',
  templateUrl: './interviewee.component.html',
  styles: [`
    .inputElemGroup {
      margin-top: 1em;
      width: 100%;
      /*text-align: center*/
    }
    .inlineElem {
      display: inline-block;
      padding-right: 1em;
    }
  `]
})
export class IntervieweeComponent {

  @Input() person: PersonInput;
  @Output() removeIntervieweeEvent = new EventEmitter();

  PersonRoles = PersonRoles;
}
