import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SuiModule, SuiAccordionModule, SuiDropdownModule, SuiTabsModule } from 'ng2-semantic-ui';

import { GraphQLClientModule } from '../shared/graphql/graphql-client.module';
import { SurveyModule } from '../survey/survey.module';
import { EvaluationModule } from '../evaluation/evaluation.module';
import { SharedModule } from '../shared/shared.module';

import { OrganizationalUnitListComponent } from './organizational-unit-list/organizational-unit-list.component';
import { OrganizationalUnitComponent } from './organizational-unit-list/organizational-unit/organizational-unit.component';
import { OrganizationalUnitEditComponent } from './organizational-unit-list/organizational-unit-edit/organizational-unit-edit.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SuiModule,
    SuiAccordionModule,
    SuiDropdownModule,
    SuiTabsModule,
    GraphQLClientModule,
    SurveyModule,
    EvaluationModule,
    SharedModule
  ],
  declarations: [OrganizationalUnitListComponent, OrganizationalUnitComponent, OrganizationalUnitEditComponent],
  providers: [],
  entryComponents: [OrganizationalUnitEditComponent]
})
export class OrganizationalUnitModule { }
