import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SuiAccordionModule, SuiCheckboxModule, SuiDropdownModule, SuiTabsModule } from 'ng2-semantic-ui';
import { SharedModule } from '../shared/shared.module';

import { UserlistComponent } from './userlist/userlist.component';
import { UserdetailComponent } from './userlist/userdetail/userdetail.component';
import { UsereditComponent } from './userlist/useredit/useredit.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SuiAccordionModule,
    SuiDropdownModule,
    SuiTabsModule,
    SuiCheckboxModule,
    SharedModule
  ],
  declarations: [UserlistComponent, UserdetailComponent, UsereditComponent],
  entryComponents: [UsereditComponent]
})
export class UsermgmtModule { }
