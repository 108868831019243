import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { compareNamesFn } from '../../../../const.exports';
import { OrganizationalUnit, Survey, OuType, SurveyStatus } from '../../../shared/graphql/graphql-client.service';

@Component({
  selector: 'bkp-organizational-unit',
  templateUrl: './organizational-unit.component.html',
  styles: [`
    .bg-color-lightgray {
      /*background-color: aliceblue !important;*/
      background-color: whitesmoke !important;
    }
  `]
})
export class OrganizationalUnitComponent implements OnInit {

  sortedChildOUs: OrganizationalUnit[];

  OuType = OuType;
  SurveyStatus = SurveyStatus;
  panelOpen = false;

  @Input() ou: OrganizationalUnit;
  @Input() evaluation: Array<{ouId: string, surveys: any}>;
  @Input() surveys: Array<Survey>;
  @Input() panelOpenIds: string[];
  @Input() subOUEnabled: boolean;

  @Output() addOUEvent = new EventEmitter<{ ouParentId: string, type: OuType }>();
  @Output() editOUEvent = new EventEmitter<string>();
  @Output() editOUContactEvent = new EventEmitter<string>();
  @Output() editOUAddressEvent = new EventEmitter<string>();
  @Output() addSurveyEvent = new EventEmitter<string>();
  @Output() editSurveyEvent = new EventEmitter<string>();
  @Output() publishSurveyEvent = new EventEmitter<Survey>();
  @Output() abortSurveyEvent = new EventEmitter<Survey>();
  @Output() loadEvaluationEvent = new EventEmitter<string>();
  @Output() addFavoriteEvent = new EventEmitter<{ ouId: string, type: OuType }>();
  @Output() panelOpenChangedEvent = new EventEmitter<string>();

  ngOnInit() {
    this.panelOpen = this.panelOpenIds.includes(this.ou.id);
    if (this.ou.organizationalUnits) {
      this.sortedChildOUs = this.ou.organizationalUnits.items.sort(compareNamesFn);
    }
  }

  getSurveysByOuId(ouId: string): Array<Survey> {
    const evalItem = this.evaluation.find((item) => item.ouId === ouId);
    return evalItem ? evalItem.surveys : [];
  }
}
