import { Injectable } from '@angular/core';

import { LoggingService } from '../logging.service';
import { CognitoUser } from 'amazon-cognito-identity-js';

import { Auth } from '@aws-amplify/auth';
import { API } from '@aws-amplify/api';
import {
  GetUserGQL,
  ListUsersGQL,
  UpdateUserGQL,
  UpdateUserInput,
  User,
  UserType
} from '../graphql/graphql-client.service';
import { DataService } from '../data.service';
import { LocalStorageService } from '../local-storage.service';
import { environment } from '../../../environments/environment';
import { UseAWSSignIn } from '../../../const.exports';

const ApiName = 'AdminQueries';

export interface AuthState {
  state: string;
  attributes?: any;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  signedIn: boolean;
  cognitoUser: CognitoUser;
  bkpUser: User;
  redirectUrl: string;

  constructor(private log: LoggingService,
              private storage: LocalStorageService,
              private dataService: DataService,
              private getUserGQL: GetUserGQL,
              private updateUserGQL: UpdateUserGQL,
              private listUsersGQL: ListUsersGQL) { }

  getUsername(): string {
    return this.cognitoUser ? this.cognitoUser.getUsername() : '';
  }

  getBKPUser(): User {
    return this.bkpUser;
  }

  isSU() {
    return this.getBKPUser().type === UserType.Su;
  }

  isBE() {
    return this.getBKPUser().type === UserType.Be;
  }

  isSignedIn(): boolean {
    return this.signedIn;
  }

  async loadBKPUser() {
    const fetchResult = await this.getUserGQL.fetch(
      { id: this.cognitoUser.getUsername() }, { fetchPolicy: 'no-cache' }).toPromise();
    this.bkpUser = fetchResult.data.getUser;
    delete this.bkpUser.__typename;
  }

  private async updateBKPUser(userInput: UpdateUserInput):
      Promise<void> {

    const updateUserInput = { ...userInput } as UpdateUserInput;
    updateUserInput.updated = this.dataService.getTimestamp();
    updateUserInput.updatedBy = this.getUsername();

    return new Promise((resolve, reject) => {
      this.updateUserGQL.mutate({
        input: updateUserInput
      }, {
      }).subscribe(async value => {
        await this.loadBKPUser();
        this.log.info(`User ${this.getBKPUser().email} successfully updated.`);
        resolve();
      }, error => {
        this.log.warn(`Error updating user ${error}.`);
        reject(`Error updating user ${error}.`);
      });
    });
  }

  async signIn(username: string, password: string): Promise<AuthState> {
    this.signedIn = false;
    try {
      const signInResult = await Auth.signIn(username, password);
      this.cognitoUser = signInResult;
      await this.loadBKPUser();
      if (signInResult && signInResult.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return { state: 'NEW_PASSWORD_REQUIRED', attributes: { ...signInResult.challengeParam.userAttributes } };
      } else {
        this.signedIn = true;
        return { state: 'SIGNED_IN' };
      }
    } catch (err) {
      switch (err.code) {
        case 'PasswordResetRequiredException':
          await Auth.forgotPassword(username);
          return { state: 'PASSWORD_RESET_REQUIRED' };
        case 'UserNotFoundException':
          return { state: 'INVALID_LOGIN_DATA' };
        case 'NotAuthorizedException':
          if (err.message.startsWith('Incorrect username or password.')) {
            return { state: 'INVALID_LOGIN_DATA' };
          } else if (err.message.startsWith('Temporary password has expired')) {
            return { state: 'TEMP_PASSWORD_EXPIRED' };
          } else if (err.message.startsWith('User is disabled.')) {
            return { state: 'USER_DISABLED' };
          } else {
            return { state: 'NOT_AUTHORIZED' };
          }
        default:
          this.log.warn('Error signing in!', err);
          return { state: 'ERROR_SIGN_IN' };
      }
    }
  }

  async updateProfile(email: string, name: string, phone: string): Promise<AuthState> {
    const user = this.getBKPUser();
    if (user.email === email && user.name === name && user.phone === phone) {
      return { state: 'PROFILE_NOT_CHANGED' };
    }

    try {
      const result = await Auth.updateUserAttributes(
          this.cognitoUser, { email, name });

      if (result) {
        try {
          await this.updateBKPUser({
            id: user.id,
            type: user.type,
            email, name, phone
          });
          return { state: 'PROFILE_UPDATED' };
        } catch (err) {
          return { state: 'PROFILE_NOT_UPDATED' };
        }
      } else {
        return { state: 'INVALID_ATTR_DATA' };
      }
    } catch (err) {
      this.log.warn('Error updating user!', err);
      return { state: 'ERROR_UPDATE_PROFILE' };
    }
  }

  async editSurrogate(surrogateEmail: string): Promise<AuthState> {
    try {
      const fetchResult = await this.listUsersGQL.fetch(
        { filter: { email: { eq: surrogateEmail } } }, { fetchPolicy: 'no-cache' }).toPromise();
      if (fetchResult.data.listUsers && fetchResult.data.listUsers.items.length === 1) {
        const surrogateUser = fetchResult.data.listUsers.items[0];
        if (surrogateUser.type === UserType.Be) {
          return { state: 'INVALID_USER_TYPE' };
        } else {
          try {
            const beUser = this.getBKPUser();
            delete surrogateUser.__typename;
            await this.updateBKPUser({ ...surrogateUser, ouTreeAccessAllowed: beUser.ouTreeAccessAllowed });
            this.log.info(`User ${surrogateEmail} is now a surrogate of ${beUser.email}.`);
            return { state: 'SURROGATE_UPDATED' };
          } catch (err) {
            this.log.warn('Error updating surrogate user!', err);
            return { state: 'ERROR_UPDATING_SURROGATE' };
          }
        }
      } else {
        return { state: 'EMAIL_DOES_NOT_EXIST' };
      }
    } catch (err) {
      this.log.warn('Error fetching surrogate user!', err);
      return { state: 'ERROR_FETCHING_SURROGATE' };
    }
  }

  async completeNewPassword(password: string, attributes?: any): Promise<AuthState> {
    try {
      const completeNewPasswordResult = await Auth.completeNewPassword(
          this.cognitoUser, password, { name: attributes.name });
      this.cognitoUser = completeNewPasswordResult;
      await this.loadBKPUser();
      this.signedIn = true;
      return { state: 'SIGNED_IN' };
    } catch (err) {
      this.log.warn('Error confirming sign up!', err);
      return { state: 'ERROR_CONFIRMING_SIGN_UP' };
    }
  }

  async completeOldPassword(oldPassword: string, password: string): Promise<AuthState> {
    try {
      await Auth.changePassword(this.cognitoUser, oldPassword, password);
      await this.signOut();
      return { state: 'PASSWORD_CHANGED' };
    } catch (err) {
      if (err.code === 'InvalidParameterException' && err.message.indexOf('satisfy constraint')) {
        return { state: 'INVALID_OLD_PASSWORD' };
      } else if (err.code === 'NotAuthorizedException' && err.message.startsWith('Incorrect username or password.')) {
        return { state: 'INVALID_OLD_PASSWORD' };
      } else if (err.code === 'LimitExceededException' && err.message.startsWith('Attempt limit exceeded, please try after some time.')) {
        return { state: 'LIMIT_EXCEEDED' };
      } else {
        this.log.warn('Error changing password!', err);
        return { state: 'ERROR_COMPLETING_OLD_PASSWORD' };
      }
    }
  }

  async confirmForgetPassword(username: string, code: string, password: string): Promise<AuthState> {
    try {
      await Auth.forgotPasswordSubmit(username, code, password);
      this.log.info(`Forget password confirmed (username: ${username})`);
      return { state: 'PASSWORD_CONFIRMED' };
    } catch (err) {
      if (err.code === 'CodeMismatchException') {
        return { state: 'CODE_MISMATCH' };
      } else {
        this.log.warn('Error confirming forget password!', err);
        return { state: 'ERROR_CONFIRMING_FORGET_PASSWORD' };
      }
    }
  }

  async createUser(groupname: string, email: string, name: string): Promise<AuthState> {
    try {
      const result = await API.post(ApiName, '/createUser', {
        body: {
          groupname,
          email,
          name
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
      });
      if (result) {
        return { state: 'USER_CREATED', attributes: { id: result.id } };
      } else {
        return { state: 'ERROR_CREATING_USER_EMPTY_RESULT' };
      }
    } catch (err)   {
      this.log.warn('Error creating user!', err);
      return { state: 'ERROR_CREATING_USER' };
    }
  }

  async updateUser(oldGroup: string, newGroup: string, email: string, name: string): Promise<AuthState> {
    try {
      const result = await API.post(ApiName, '/updateUser', {
        body: {
          oldGroup,
          newGroup,
          email,
          name
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
      });
      if (result) {
        this.log.info(result.message);
        return { state: 'USER_UPDATED' };
      } else {
        return { state: 'ERROR_UPDATING_USER_EMPTY_RESULT' };
      }
    } catch (err)   {
      this.log.warn('Error updating user!', err);
      return { state: 'ERROR_UPDATING_USER' };
    }
  }

  async deleteUser(username: string): Promise<AuthState> {
    try {
      const result = await API.post(ApiName, '/deleteUser', {
        body: {
          username
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
      });
      if (result) {
        this.log.info(result.message);
        return { state: 'USER_DELETED' };
      } else {
        return { state: 'ERROR_DELETING_USER_EMPTY_RESULT' };
      }
    } catch (err)   {
      this.log.warn('Error deleting user!', err);
      return { state: 'ERROR_DELETING_USER' };
    }
  }

  async signOut(): Promise<AuthState> {
    try {
      await Auth.signOut();
      this.signedIn = false;
      this.cognitoUser = null;
      this.bkpUser = null;
      this.log.info('Successfully signed out.');
      return { state: 'SIGNED_OUT' };
    } catch (err) {
      this.log.warn('Error signing out!', err);
      return { state: 'ERROR_SIGNING_OUT' };
    }
  }
}
