import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NavLabel } from '../../../const.exports';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'bkp-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent {

  @Input() headerLabel: string;

  constructor(private router: Router, private authService: AuthService) { }

  async showOU() {
    await this.router.navigate([NavLabel.OU]);
  }

  async showUserMgmt() {
    await this.router.navigate([NavLabel.Usermgmt]);
  }

  async editProfile() {
    await this.router.navigate([NavLabel.EditProfile]);
  }

  async editSurrogate() {
    await this.router.navigate([NavLabel.EditSurrogate]);
  }

  async changePassword() {
    await this.router.navigate([NavLabel.ChangePw]);
  }

  async onSignOut() {
    await this.router.navigate([NavLabel.Logout]);
  }
}
