import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class UIService {

  constructor() { }

  showToast(msg: string, toastClass= 'neutral') {
    $('body').toast({
      position: 'top center',
      class: toastClass,
      // displayTime: 'auto',
      message: msg
    });
  }

  showErrorToast(msg: string) {
    $('body').toast({
      position: 'top center',
      class: 'error',
      displayTime: 10000,
      message: msg
    });
  }
}
