import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from './auth.service';
import { NavLabel } from '../../../const.exports';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.authService.isSignedIn()) {
      return true;
    } else {
      // Store the attempted URL for redirecting
      this.authService.redirectUrl = state.url;

      // Navigate to the login page with extras
      this.router.navigate([NavLabel.Login]);
      return false;
    }
  }
}
