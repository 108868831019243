import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { SuiModule } from 'ng2-semantic-ui';
import { AppRoutingModule } from './app-routing.module';
import { OrganizationalUnitModule } from './organizational-unit/organizational-unit.module';
import { UsermgmtModule } from './usermgmt/usermgmt.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';

// AWS Amplify
import Amplify from '@aws-amplify/core';
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    SuiModule,
    AppRoutingModule,
    OrganizationalUnitModule,
    UsermgmtModule,
    SharedModule.forRoot()
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
  ],
  entryComponents: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
