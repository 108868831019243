import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';
import { ISurveyEditContext, SurveyEditComponent } from './survey-edit.component';
import { Survey } from '../../../shared/graphql/graphql-client.service';

export class SurveyEdit extends ComponentModalConfig<ISurveyEditContext, void, void> {
  constructor(survey: Survey, size = ModalSize.Large) {
    super(SurveyEditComponent, { survey });

    this.isClosable = false;
    this.transitionDuration = 200;
    this.size = size;
  }
}
