import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';
import { IOrganizationalUnitEditContext, OrganizationalUnitEditComponent } from './organizational-unit-edit.component';
import { OrganizationalUnit } from '../../../shared/graphql/graphql-client.service';

export class OrganizationalUnitEdit extends ComponentModalConfig<IOrganizationalUnitEditContext, void, void> {
  constructor(ou: OrganizationalUnit, idxPanelOpen: number, isSU: boolean, size = ModalSize.Small) {
    super(OrganizationalUnitEditComponent, { ou, idxPanelOpen, isSU });

    this.isClosable = false;
    this.transitionDuration = 200;
    this.size = size;
  }
}
