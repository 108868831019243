import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DeadlineIdParam, EvaluationUrl, OUIdParam } from '../../../const.exports';

@Component({
  selector: 'bkp-evaluation-survey',
  templateUrl: './evaluation-survey.component.html',
  styles: []
})
export class EvaluationSurveyComponent implements OnInit {

  @Input() ouId: string;
  @Input() deadline: number;

  safeURL: SafeResourceUrl;

  constructor(private route: ActivatedRoute, private router: Router, private domSanitizer: DomSanitizer) { }

  ngOnInit() {
    this.safeURL = this.domSanitizer.bypassSecurityTrustResourceUrl(
      `${EvaluationUrl}/?${OUIdParam}=${this.ouId},${DeadlineIdParam}=${this.deadline}`);
  }
}
