import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { SuiLocalizationService, SuiModalService } from 'ng2-semantic-ui';
import de from 'ng2-semantic-ui/locales/de';
import { I18n } from '@aws-amplify/core';

import { AuthService } from './shared/auth/auth.service';

import { AmplifyDictionary, DeployedOn, Title, Version } from '../const.exports';

declare var $: any;

@Component({
  selector: 'bkp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  $ = $;
  title = Title;
  version = Version;
  deployedOn = DeployedOn;

  constructor(private router: Router,
              private modalService: SuiModalService,
              public localizationService: SuiLocalizationService,
              private authService: AuthService) {

    // Load the german translations into the Localization Service
    localizationService.load('de', de);
    // Set the current language to german.
    localizationService.setLanguage('de');

    // Set language (for Amplify components)
    I18n.setLanguage('de');
    I18n.putVocabularies(AmplifyDictionary);
  }
}
