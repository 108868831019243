import { Component } from '@angular/core';

import { GetUserTypeLabel } from '../../../../const.exports';

import {
  CreateUserInput,
  OrganizationalUnit,
  UpdateUserInput,
  UserType
} from '../../../shared/graphql/graphql-client.service';
import { SuiModal } from 'ng2-semantic-ui';

import { UIUser } from '../userlist.component';

export interface IUserEditContext {
  user: UIUser;
  allOrganizationalUnits: Array<OrganizationalUnit>;
  idxPanelOpen: number;
}

export interface IUserEditForm {
  userIdToRemove?: string;
  userForm: CreateUserInput | UpdateUserInput;
}

@Component({
  selector: 'bkp-useredit',
  templateUrl: './useredit.component.html',
  styles: []
})
export class UsereditComponent {

  UserType = UserType;
  getUserTypeLabel = GetUserTypeLabel;

  allOUs: Array<{ id: string, name: string, selected: boolean }>;
  editHeader: string;
  userEditForm: IUserEditForm = {
    userForm: { name: '', phone: '', email: '' } as CreateUserInput
  };
  updating: boolean;

  removeUserConfirmEnabled = false;

  constructor(public modal: SuiModal<IUserEditContext, IUserEditForm, void>) {
    const user = this.modal.context.user;
    this.allOUs = this.modal.context.allOrganizationalUnits.map((ou: OrganizationalUnit) => ({
      id: ou.id,
      name: ou.name,
      selected: user.ouTreeAccessAllowed ? user.ouTreeAccessAllowed.includes(ou.id) : false
    }));

    if (user.id) {
      this.editHeader = `Nutzer "${ user.name }" bearbeiten`;
      this.userEditForm.userForm = { ...user } as UpdateUserInput;
      this.updating = true;
    } else {
      user.type = UserType.Be;
      this.editHeader = `Neuen Nutzer erstellen`;
      this.userEditForm.userForm = { ...user } as CreateUserInput;
      this.updating = false;
    }
  }

  removeUser() {
    this.userEditForm.userIdToRemove = this.modal.context.user.id;
    this.modal.approve(this.userEditForm);
  }

  save() {
    this.userEditForm.userForm.ouTreeAccessAllowed = this.allOUs.filter(ou => ou.selected).map(ou => ou.id);
    this.modal.approve(this.userEditForm);
  }

  setUserType(userType: UserType) {
    this.userEditForm.userForm.type = userType;
  }

}
