/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:252141fb-f7fd-4400-93b7-0c650f98c867",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_vfjN61cxe",
    "aws_user_pools_web_client_id": "1pa2quu2atuls13fgv2d66bd5o",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://s9ad00wd71.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
