import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { map } from 'rxjs/operators';

import { ListOrganizationalUnitsGQL, OrganizationalUnit, OuType } from './graphql/graphql-client.service';
import { compareNamesFn } from '../../const.exports';

@Injectable({
  providedIn: "root"
})
export class AllOrganizationalUnitsResolver implements Resolve<Array<OrganizationalUnit>> {

  constructor(private listOUGQL: ListOrganizationalUnitsGQL) {
  }

  resolve() {
    return this.listOUGQL.fetch({ type: OuType.AuL })
      .pipe(
        map((result: any) => result.data.listOrganizationalUnits.items),
        map((result: any) => result.sort(compareNamesFn))
      );
  }

}
