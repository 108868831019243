import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { LoggingService, LogMsg, MsgType } from '../logging.service';

@Component({
  selector: 'bkp-logging',
  template: `
    <div *ngIf="logMsg" style="text-align: center">
      <div class="msg-color">Logging Message: {{logMsg.getMessageAsString()}}
        / Optional Parameters: {{logMsg.getOptionalParamsAsString()}}</div>
    </div>
  `,
  styles: [`
      .msg-color {
          color: red;
      }
  `]
})
export class LoggingComponent implements OnInit, OnDestroy {
  private subscription: Subscription;

  logMsg: LogMsg;

  constructor(private log: LoggingService) { }

  ngOnInit() {
    this.subscription = this.log.getLogMessages().subscribe((msg: LogMsg) => {
      this.logMsg = msg;
      if (msg.getType() === MsgType.info) {
        console.log(this.logMsg.getMessage(), this.logMsg.getOptionalParams());
      } else if (msg.getType() === MsgType.error) {
        console.error(this.logMsg.getMessage(), this.logMsg.getOptionalParams());
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
