import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SuiModalService } from 'ng2-semantic-ui';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  CreateUserGQL,
  CreateUserInput,
  DeleteUserGQL,
  GetUserGQL,
  ListOrganizationalUnitsGQL,
  ListUsersGQL,
  OrganizationalUnit,
  UpdateUserGQL,
  UpdateUserInput,
  User
} from '../../shared/graphql/graphql-client.service';
import { compareNamesFn, GetUserTypeLabel } from '../../../const.exports';
import { UserEdit } from './useredit/useredit-dialog';
import { DataService } from '../../shared/data.service';
import { IUserEditForm } from './useredit/useredit.component';
import { AuthService } from '../../shared/auth/auth.service';
import { UIService } from '../../shared/ui.service';

export interface UIUser extends User {
  ouTreeAccessAllowedNames: string[];
}

@Component({
  selector: 'bkp-userlist',
  templateUrl: './userlist.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserlistComponent implements OnInit {

  users: Observable<Array<User>>;
  allOrganizationalUnits: Array<OrganizationalUnit>;
  loading: boolean;

  panelOpenIds = [];

  userMutationOptions = {
    refetchQueries: [{
      query: this.listUsersGQL.document,
      variables: {}
    }]
  };

  // @Output() addOUEvent = new EventEmitter<{ ouParentId: string, type: OuType }>();
  // @Output() editOUEvent = new EventEmitter<string>();
  @Output() panelOpenChangedEvent = new EventEmitter<string>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private listUsersGQL: ListUsersGQL,
    private getUserGQL: GetUserGQL,
    private createUserGQL: CreateUserGQL,
    private updateUserGQL: UpdateUserGQL,
    private deleteUserGQL: DeleteUserGQL,
    private listOUGQL: ListOrganizationalUnitsGQL,
    private modalService: SuiModalService,
    private dataService: DataService,
    private uiService: UIService,
    private authService: AuthService) {
  }

  ngOnInit() {
    this.loading = true;
    this.activatedRoute.data.subscribe(data => {
      this.allOrganizationalUnits = data.allOrganizationalUnits;
      this.users = this.listUsersGQL.watch().valueChanges
        .pipe(
          map((result: any) => result.data.listUsers.items),
          map((users: any) => users.sort(compareNamesFn)),
          map((users: any) => users.map(user => {
            if (user.ouTreeAccessAllowed && user.ouTreeAccessAllowed.length > 0) {
              const ouTreeAccessAllowedNames = [];
              user.ouTreeAccessAllowed.forEach((ouId: string) => {
                const ouFound: OrganizationalUnit = data.allOrganizationalUnits.find(
                  (ou: OrganizationalUnit) => ouId === ou.id);
                if (ouFound) {
                  ouTreeAccessAllowedNames.push(ouFound.name);
                }
              });
              this.loading = false;
              return { ...user, ouTreeAccessAllowedNames };
            }
            this.loading = false;
            return user;
          })),
        );
    });
  }

  addUser() {
    this.modalService
      .open(new UserEdit({} as UIUser, this.allOrganizationalUnits, 0))
      .onApprove(async (result: IUserEditForm) => {
        const user = result.userForm as CreateUserInput;
        user.created = 0; // Overwritten by AppSync resolver with current timestamp!
        user.createdBy = this.authService.getUsername();

        const authState = await this.authService.createUser(
            GetUserTypeLabel(user.type), user.email, user.name);
        switch (authState.state) {
          case 'USER_CREATED':
            user.id = authState.attributes.id;
            this.createUserGQL.mutate({
              input: user
            }, this.userMutationOptions).subscribe(value => {
              this.uiService.showToast(`Benutzer "${user.email}" erstellt.`);
            }, error => {
              this.uiService.showErrorToast(
                `Fehler beim Erstellen des Benutzers "${user.email}"!`);
            });
            break;
          case 'ERROR_CREATING_USER_EMPTY_RESULT':
          case 'ERROR_CREATING_USER':
          default:
            this.uiService.showErrorToast(
              `Fehler beim Erstellen des Benutzers "${user.email}"!`);
        }
      })
      .onDeny((result) => {
      });
  }

  editUserData(userId: string, idxPanelOpen: number) {
    this.getUserGQL.fetch({ id: userId }, { fetchPolicy: 'no-cache' }).subscribe(fetchResult => {
      const user = fetchResult.data.getUser;
      delete user.__typename;

      this.modalService
        .open(new UserEdit({ ...user } as UIUser, this.allOrganizationalUnits, idxPanelOpen))
        .onApprove(async (result: IUserEditForm) => {
          if (result.userIdToRemove) {
            const authState = await this.authService.deleteUser(user.email);
            switch (authState.state) {
              case 'USER_DELETED':
                this.deleteUserGQL.mutate({
                  input: { id: result.userIdToRemove }
                }, this.userMutationOptions).subscribe(value => {
                  this.uiService.showToast(`Benutzer gelöscht.`);
                }, error => {
                  this.uiService.showErrorToast(`Fehler beim Löschen des Benutzers!`);
                });
                break;
              case 'ERROR_DELETING_USER_EMPTY_RESULT':
              case 'ERROR_DELETING_USER':
              default:
                this.uiService.showErrorToast(`Fehler beim Löschen des Benutzers!`);
            }
          } else {
            const userInput = result.userForm as UpdateUserInput;
            userInput.updated = this.dataService.getTimestamp();
            userInput.updatedBy = this.authService.getUsername();

            const authState = await this.authService.updateUser(
              GetUserTypeLabel(user.type), GetUserTypeLabel(userInput.type),
              userInput.email, userInput.name);
            switch (authState.state) {
              case 'USER_UPDATED':
                this.updateUserGQL.mutate({
                  input: userInput
                }, this.userMutationOptions).subscribe(value => {
                  this.uiService.showToast(`Benutzer "${user.email}" geändert.`);
                }, error => {
                  this.uiService.showErrorToast(
                    `Fehler beim Ändern des Benutzers "${user.email}"!`);
                });
                break;
              case 'ERROR_UPDATING_USER_EMPTY_RESULT':
              case 'ERROR_UPDATING_USER':
              default:
                this.uiService.showErrorToast(
                  `Fehler beim Ändern des Benutzers "${user.email}"!`);
            }
          }
        })
        .onDeny((result) => {
        });
    });
  }

  panelOpenChanged(userId: string) {
    const idx = this.panelOpenIds.indexOf(userId);
    if (idx !== -1) {
      this.panelOpenIds.splice(idx, 1);
    } else {
      this.panelOpenIds.push(userId);
    }
  }

}
