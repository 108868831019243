import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrganizationalUnit, User, UserType } from '../../../shared/graphql/graphql-client.service';
import { UIUser } from '../userlist.component';
import { GetUserTypeLabel } from '../../../../const.exports';

@Component({
  selector: 'bkp-userdetail',
  templateUrl: './userdetail.component.html',
  styles: []
})
export class UserdetailComponent {

  @Input() user: UIUser;
  @Input() allOrganizationalUnits: Array<OrganizationalUnit>;
  @Input() panelOpenIds: string[];

  @Output() editUserDataEvent = new EventEmitter<string>();
  @Output() editUserPermissionsEvent = new EventEmitter<string>();
  @Output() panelOpenChangedEvent = new EventEmitter<string>();

  UserType = UserType;
  getUserTypeLabel = GetUserTypeLabel;

  panelOpen = false;
}
