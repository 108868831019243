import { Component } from '@angular/core';

import {
  AlphaDekadeLogoUrl,
  AuLBBLogoUrl,
  AuLLogoUrl,
  BKPLogoUrl,
  BMBFLogoUrl, DeployedOn, KHuFLogoUrl,
  Title,
  Version
} from '../../../const.exports';

declare var $: any;

@Component({
  selector: 'bkp-footer',
  templateUrl: './footer.component.html',
  styles: []
})
export class FooterComponent {

  $ = $;
  title = Title;
  version = Version;
  deployedOn = DeployedOn;

  bkpLogoUrl = BKPLogoUrl;
  aulLogoUrl = AuLLogoUrl;
  aulBBLogoUrl = AuLBBLogoUrl;
  alphaDekadeLogoUrl = AlphaDekadeLogoUrl;
  bmbfLogoUrl = BMBFLogoUrl;
  khufLogoUrl = KHuFLogoUrl;

  showSiteNotice() {
    $('#sitenotice').modal('show');
  }

  showDataSecurity() {
    $('#datasecurity').modal('show');
  }
}
