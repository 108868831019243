import { environment } from './environments/environment';
import {
  OuType,
  PersonRole,
  QuestionnaireType,
  QuestionType,
  UserType
} from './app/shared/graphql/graphql-client.service';

export const UseAWSSignIn = false;

export const Title = 'Verwaltung';
export const Version = '0.14.4';
export const DeployedOn = '05.10.2020 18:00 Uhr';

export const GraphQLUri = 'https://jtj7wonpijcyhfukdaaremjjy4.appsync-api.eu-central-1.amazonaws.com/graphql';
export const XApiKey = 'da2-ig6ifotemjhgnlld72mngvlhjq';

export const SurveyUrl = 'https://basiskomplus-web.kopfhandundfuss.net/?umfrageId=';
export const EvaluationUrl = 'https://basiskomplus-evaluation.kopfhandundfuss.net';

export const OUIdParam = 'id';
export const DeadlineIdParam = 'date';

export const HttpRetries = 3;

export const AlphaDekadeLogoUrl = '/assets/images/logo-alpha-dekade.png';
export const AuLLogoUrl = '/assets/images/logo-aul.png';
export const AuLBBLogoUrl = '/assets/images/logo-aul-bb.png';
export const BKPLogoUrl = '/assets/images/logo-bkp.png';
export const BMBFLogoUrl = '/assets/images/logo-bmbf.svg';
export const KHuFLogoUrl = '/assets/images/logo-khuf.png';

export const QuestionnaireId = {
  SelbstCheck: 'e2b02150-1735-11e9-ae33-f32faa1effa6',
  FremdCheckDV: 'fe426125-78b5-4f82-8bb3-1b31ffc0a044',
  FremdCheckPA: 'fe526125-78b5-4f82-8bb3-1b31ffc0a044',
  FremdCheckBI: 'fe626125-78b5-4f82-8bb3-1b31ffc0a044',
  BetriebsCheck: 'bcb444f9-48b5-4ac2-b22e-e126a0acc239'
};

export const PersonRoles = [
  { label: 'Beschäftigte(r)', value: PersonRole.Employee },
  { label: 'Direkte(r) Vorgesetzte(r)', value: PersonRole.ManagerDv },
  { label: 'Personalabteilung', value: PersonRole.ManagerPa },
  { label: 'Betriebliche Interessenvertretung', value: PersonRole.ManagerBi },
  { label: 'Geschäftsführung', value: PersonRole.Employer }
];

export const QuestionTypes = [
  { label: 'Textfeld', value: QuestionType.TextInput },
  { label: 'Einfachauswahl', value: QuestionType.SingleChoice }
  // { label: 'Mehrfachauswahl', value: QuestionType.MultipleChoice }
];

export const NavLabel = {
  OU: '/ou',
  Usermgmt: '/usermgmt',
  Login: '/login',
  Logout: '/logout',
  EditProfile: '/profile',
  EditSurrogate: '/surrogate',
  ChangePw: '/changepw',
  EvalSurvey: '/evalsurvey'
};

export const CSVExpSep = ';';

export const compareNamesFn = (a, b) => {
  if (a.name < b.name) {
    return -1;
  } else if (a.name > b.name) {
    return 1;
  } else {
    return 0;
  }
};

export const GetUserTypeLabel = (userType: UserType) => {
  switch (userType) {
    case UserType.Su: return 'Superuser';
    case UserType.Be: return 'Beratungseinheiten';
    default: return '';
  }
};

export const GetOULabel = (ouType: OuType) => {
  switch (ouType) {
    case OuType.AuL: return 'Landesorganisation';
    case OuType.Company: return 'Betrieb';
    case OuType.Department: return 'Abteilung';
  }
};

export const GetQuestionnaireTypeLabel = (questionnaireType: QuestionnaireType) => {
  switch (questionnaireType) {
    case QuestionnaireType.SelbstCheck: return 'SelbstCheck';
    case QuestionnaireType.FremdCheckDv: return 'FremdCheck für direkte Vorgesetzte';
    case QuestionnaireType.FremdCheckPa: return 'FremdCheck für Personalabteilung';
    case QuestionnaireType.FremdCheckBi: return 'FremdCheck für betriebliche Interessenvertretung';
    case QuestionnaireType.BetriebsCheck: return 'BetriebsCheck';
  }
};

export const AmplifyDictionary = {
  de: {
    'Sign In': 'Anmeldung',
    'Sign Out': 'Abmeldung'
  }
};
