import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavLabel } from '../../../const.exports';
import { AuthService, AuthState } from './auth.service';
import { UIService } from '../ui.service';

declare var $: any;

@Component({
  selector: 'bkp-auth',
  templateUrl: './auth.component.html',
  styles: []
})
export class AuthComponent implements OnInit {

  ErrorMsg = {
    NOT_AUTHORIZED: [
      'Nicht autorisiert', 'Die Anmeldung war nicht erfolgreich.'],
    INVALID_LOGIN_DATA: [
      'Eingabefehler', 'Der eingegebene Benutzername oder das Kennwort sind nicht korrekt.'],
    NEW_PASSWORD_REQUIRED: [
      'Das temporäre Kennwort ist abgelaufen.', ''],
    USER_DISABLED: [
      'Der Nutzer ist deaktiviert.', 'Für die Aktivierung des Nutzers, wenden Sie sich bitte an Ihren Administrator.'],
    CODE_MISMATCH: [
      'Bestätigungscode', 'Der Bestätigungscode ist nicht korrekt.'],
    INVALID_OLD_PASSWORD: [
      'Eingabefehler', 'Das alte Kennwort ist nicht korrekt.'],
    ERROR_COMPLETING_OLD_PASSWORD: [
      'Eingabefehler', 'Das neue Kennwort entspricht nicht den Anforderungen.'],
    LIMIT_EXCEEDED: [
      'Anmeldungsfehler', 'Sie haben zuoft das falsche Kennwort eingegeben. Bitte warten Sie für den nächsten Versuch.'],
    PASSWORD_CHANGED: [
      'Das Kennwort wurde geändert.', 'Bitte melden Sie sich mit dem neuen Kennwort an.'],
    EMAIL_DOES_NOT_EXIST: [
      'Ein Nutzer mit der angegebenen E-Mail-Adresse konnte nicht gefunden werden.', ''],
    INVALID_USER_TYPE: [
      'Dieser Nutzer ist ein Betriebseinheiten-Nutzer und kann nicht als Stellvertreter eingesetzt werden.', ''],
    ERROR_UPDATING_SURROGATE: [
      'Der ausgewählte Nutzer konnte nicht aktualisiert werden.', 'Wenden Sie sich bitte an Ihren Administrator.'],
  };

  $ = $;
  authCredentialsForm: {
    email: string,
    password: string,
    oldPassword: string,
    confirmPassword: string,
    confirmCode: string
  } = {
    email: '',
    password: '',
    oldPassword: '',
    confirmPassword: '',
    confirmCode: ''
  };

  authAttributesForm: {
    name: string,
    phone_number: string
  } = {
    name: '',
    phone_number: ''
  };

  surrogateEmail: string;
  authState: AuthState;

  constructor(private router: Router,
              public authService: AuthService,
              private uiService: UIService) {}

  ngOnInit() {
    switch (this.router.url) {
      case NavLabel.Login:
        return this.showSignIn();
      case NavLabel.Logout:
        return this.signOut();
      case NavLabel.EditProfile:
        const user = this.authService.getBKPUser();
        if (user) {
          this.authAttributesForm.name = user.name;
          this.authAttributesForm.phone_number = user.phone;
          this.authCredentialsForm.email = user.email;
          return this.showModal('#edit-profile');
        }
        break;
      case NavLabel.EditSurrogate:
        return this.showModal('#edit-surrogate');
      case NavLabel.ChangePw:
        this.authCredentialsForm.email = this.authService.getBKPUser() && this.authService.getBKPUser().email;
        this.authCredentialsForm.password = '';
        this.authCredentialsForm.oldPassword = '';
        return this.showModal('#change-password');
    }
  }

  async showSignIn() {
    return await this.showModal('#sign-in');
  }

  async navToRedirectUrl() {
    return await this.router.navigate([this.authService.redirectUrl ? this.authService.redirectUrl : '/']);
  }

  async navToRootUrl() {
    return await this.router.navigate(['/']);
  }

  async signIn() {
    this.authState = await this.authService.signIn(this.authCredentialsForm.email, this.authCredentialsForm.password);
    switch (this.authState.state) {
      case 'SIGNED_IN':
        this.uiService.showToast(`Anmeldung erfolgreich.`);
        return this.navToRedirectUrl();
      case 'NEW_PASSWORD_REQUIRED':
        const user = this.authService.getBKPUser();
        this.authAttributesForm = { name: user.name, phone_number: user.phone };
        this.authCredentialsForm.password = '';
        return this.showModal('#new-password-required');
      case 'PASSWORD_RESET_REQUIRED':
        return this.showModal('#confirm-forget-password');
      case 'INVALID_LOGIN_DATA':
      case 'USER_DISABLED':
      case 'ERROR_SIGN_IN':
      default:
        this.uiService.showErrorToast(`Konnte den Nutzer "${this.authCredentialsForm.email}" nicht anmelden!`);
        return this.navToRootUrl();
    }
  }

  async completeNewPassword() {
    this.authState = await this.authService.completeNewPassword(this.authCredentialsForm.password, this.authAttributesForm);
    switch (this.authState.state) {
      case 'SIGNED_IN':
        this.uiService.showToast(`Willkommen bei BasisKomPlus!`);
        return this.navToRedirectUrl();
      case 'ERROR_CONFIRMING_SIGN_UP':
      case 'ERROR_UPDATING_USER':
      default:
        this.uiService.showErrorToast(`Konnte das Kennwort für den Nutzer "${this.authCredentialsForm.email}" nicht ändern!`);
        return this.navToRootUrl();
    }
  }

  async completeOldPassword() {
    this.authState = await this.authService.completeOldPassword(
      this.authCredentialsForm.oldPassword, this.authCredentialsForm.password);
    switch (this.authState.state) {
      case 'INVALID_OLD_PASSWORD':
      case 'LIMIT_EXCEEDED':
        return this.showModal('#change-password');
      case 'PASSWORD_CHANGED':
        this.uiService.showToast('Ihr Kennwort wurde erfolgreich geändert, bitte melden Sie sich mit Ihrem neuen Kennwort an.');
        return this.showSignIn();
      case 'ERROR_COMPLETING_OLD_PASSWORD':
      default:
        this.uiService.showErrorToast(`Konnte das Kennwort für den Nutzer "${this.authCredentialsForm.email}" nicht ändern!`);
        return this.navToRootUrl();
    }
  }

  async confirmEditSurrogate() {
    this.authState = await this.authService.editSurrogate(this.surrogateEmail);
    switch (this.authState.state) {
      case 'EMAIL_DOES_NOT_EXIST':
      case 'INVALID_USER_TYPE':
        return this.showModal('#edit-surrogate');
      case 'SURROGATE_UPDATED':
        this.uiService.showToast('Der Stellvertreter wurde eingetragen.');
        return this.navToRedirectUrl();
      case 'ERROR_FETCHING_SURROGATE':
      case 'ERROR_UPDATING_SURROGATE':
      default:
        this.uiService.showErrorToast(`Konnte den Stellvertreter nicht eintragen!`);
        return this.navToRootUrl();
    }
  }

  async confirmEditProfile() {
    this.authState = await this.authService.updateProfile(
      this.authCredentialsForm.email,
      this.authAttributesForm.name,
      this.authAttributesForm.phone_number);
    switch (this.authState.state) {
      case 'PROFILE_UPDATED':
        this.uiService.showToast('Ihr Profil wurde aktualisiert.');
        return this.navToRedirectUrl();
      case 'INVALID_ATTR_DATA':
      case 'PROFILE_NOT_UPDATED':
      case 'ERROR_UPDATE_PROFILE':
      default:
        this.uiService.showErrorToast('Ihr Profil konnte nicht aktualisiert werden!');
        return this.navToRootUrl();
    }
  }

  async confirmForgetPassword() {
    this.authState = await this.authService.confirmForgetPassword(
      this.authCredentialsForm.email,
      this.authCredentialsForm.confirmCode,
      this.authCredentialsForm.password);
    switch (this.authState.state) {
      case 'CODE_MISMATCH':
      case 'ERROR_CONFIRMING_FORGET_PASSWORD':
        return this.showModal('#confirm-forget-password');
        break;
      case 'PASSWORD_CONFIRMED':
        this.uiService.showToast('Ihr Kennwort wurde erfolgreich geändert, bitte melden Sie sich mit Ihrem neuen Kennwort an.');
        return this.showSignIn();
      default:
        this.uiService.showErrorToast(`Konnte das Kennwort für den Nutzer "${this.authCredentialsForm.email}" nicht ändern!`);
        return this.navToRootUrl();
    }
  }

  async signOut() {
    this.authState = await this.authService.signOut();
    switch (this.authState.state) {
      case 'SIGNED_OUT':
      case 'ERROR_SIGNING_OUT':
      default:
        this.uiService.showToast(`Sie haben sich abgemeldet.`);
        return this.navToRootUrl();
    }
  }

  showModal(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      $(id).modal({
        closable: false,
        onVisible: () => {
          resolve();
        },
        onApprove: () => {
          return true;
        },
        onDeny: () => {
          return true;
        },
      }).modal('show');
    });
  }
}
