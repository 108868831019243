import { ComponentModalConfig, ModalSize } from 'ng2-semantic-ui';
import { IUserEditContext, UsereditComponent } from './useredit.component';
import { UIUser } from '../userlist.component';
import { OrganizationalUnit } from '../../../shared/graphql/graphql-client.service';

export class UserEdit extends ComponentModalConfig<IUserEditContext, void, void> {
  constructor(user: UIUser, allOrganizationalUnits: Array<OrganizationalUnit>, idxPanelOpen: number, size = ModalSize.Small) {
    super(UsereditComponent, { user, allOrganizationalUnits, idxPanelOpen });

    this.isClosable = false;
    this.transitionDuration = 200;
    this.size = size;
  }
}
