import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SuiAccordionModule } from 'ng2-semantic-ui';

import { EvaluationOverviewComponent } from './evaluation-overview/evaluation-overview.component';
import { EvaluationSurveyComponent } from './evaluation-survey/evaluation-survey.component';

@NgModule({
  imports: [
    CommonModule,
    SuiAccordionModule
  ],
  declarations: [EvaluationOverviewComponent, EvaluationSurveyComponent],
  exports: [EvaluationOverviewComponent]
})
export class EvaluationModule { }
