import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuard } from './shared/auth/auth.guard';
import { AuthSuGuard } from './shared/auth/auth-su.guard';
import { OrganizationalUnitListComponent } from './organizational-unit/organizational-unit-list/organizational-unit-list.component';
import { AuthComponent } from './shared/auth/auth.component';
import { UserlistComponent } from './usermgmt/userlist/userlist.component';
import { NavLabel } from '../const.exports';
import { AllOrganizationalUnitsResolver } from './shared/all-organizational-units.resolver';
import { EvaluationSurveyComponent } from './evaluation/evaluation-survey/evaluation-survey.component';

const routes: Routes = [
  { path: '', redirectTo: NavLabel.OU.substring(1), pathMatch: 'full' },
  { path: NavLabel.OU.substring(1), component: OrganizationalUnitListComponent, canActivate: [AuthGuard] },
  { path: NavLabel.Usermgmt.substring(1), component: UserlistComponent, canActivate: [AuthSuGuard],
    resolve: {
      allOrganizationalUnits: AllOrganizationalUnitsResolver
    }
  },
  { path: NavLabel.Login.substring(1), component: AuthComponent },
  { path: NavLabel.Logout.substring(1), component: AuthComponent },
  { path: NavLabel.EditProfile.substring(1), component: AuthComponent, canActivate: [AuthGuard] },
  { path: NavLabel.EditSurrogate.substring(1), component: AuthComponent, canActivate: [AuthGuard] },
  { path: NavLabel.ChangePw.substring(1), component: AuthComponent, canActivate: [AuthGuard] },
  { path: NavLabel.EvalSurvey.substring(1), component: EvaluationSurveyComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
